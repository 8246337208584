const values = {
    "PACKAGES_CONFIGURATIONS": "Config. de paquetes",
    "PACKAGE_DELIVERY": "Entrega de paquete",
    "PACKAGES_DELIVERY": "Entregas de paquetes",
    "NEW_PACKAGE_DELIVERY": "Nueva entrega de paquete",
    "EDIT_PACKAGE_DELIVERY": "Editar entrega de paquete",
    "REMOVE_PACKAGE_DELIVERY": "Eliminar entrega de paquete",
    "REMOVE_PACKAGE_DELIVERY_CONFIRMATION": "¿Está seguro que desea eliminar esta entrega de paquete?",
    "NO_PACKAGE_DELIVERY_TITLE": "No hay entregas de paquetes en el sistema!!!",
    "NO_PACKAGE_DELIVERY_SUBTITLE": "Verifíca si estás aplicando algún filtro",
    "ITEMS_PLACEHOLDER": "Agregue Artículos",
    "DELIVERY_TYPE_PLACEHOLDER": "Tipo de envío",
    "EDIT_PACKAGE_ITEM": "Editar artículo",
    "EDIT_PACKAGE_ITEM_CONFIRMATION": "¿Está seguro que desea editar el artículo?",
    "REMOVE_PACKAGE_ITEM": "Eliminar artículo",
    "REMOVE_PACKAGE_ITEM_CONFIRMATION": "¿Está seguro que desea eliminar el artículo?",
    "NO_PACKAGE_DELIVERY_ITEM_TITLE": "La categoría ",
    "NO_PACKAGE_DELIVERY_ITEM2_TITLE": " aún no tiene artículos!!!",
    "NO_PACKAGE_DELIVERY_TITLE_ITEM_SUBTITLE": "Adelántate y agrega el primero!!!",
    "PACKAGE_DELIVERY_ORDER_FILTER_PLACEHOLDER": "Cliente, Contacto, Número de orden",
    "PACKAGE_DELIVERY_SHIPPING_FILTER_PLACEHOLDER": "Número de envio",
    "ASSIGN_SHIPPING_NUMBER": "Asignar número de envío",
    "INVALID_PAYMENTS_TITLE": "Hay errores con los pagos",
    "INVALID_PAYMENTS_SUBTITLE": "Los pagos no estan completos o contienen errores. Si no se corrigen no se guardarán los cambios",

}

export default values;
