import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    custom_category_container: {
        display: 'flex',
        flexDirection: 'column',
    },
    prices_container: {
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        fontSize: '18px',
        fontWeight: 800
    },
    custom_category_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    root_elements_container: {
        margin:'0px 16px',
    },
    elements_container: {
        margin: '0px 16px',
        maxHeight: 'calc(100vh - 653px)',
        overflow: 'auto'
    },
    custom_category_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: '250px auto 120px 50px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    custom_row_buttons: {
        display: 'flex',
        gap: 8
    },
    custom_category_with_items_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'flex',
        columnGap: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    custom_category_info_container_small: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        placeItems: 'stretch',
        rowGap: 16,
        columnGap: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
    },
    menu_container_small: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        "& > *": {
            marginLeft: '4px'
        }
    },
    filter_container: {
        display: "grid",
        marginTop: '16px',
        gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
        rowGap: 16,
        "& button": {
            justifySelf: "end"
        }
    },
    tools_container: {},
    search_btn: {
        justifySelf: 'flex-end'
    }
}));

export {useStyles}