const values = {
    HOST: 'https://api.alocubatravel.com',
    IMAGE_CDN_URL: 'https://ik.imagekit.io/oiimkmcyr/{imageName}?tr=w-{width},h-{height}',
    CLIENT_ORDER_TRACKER_UI_URL: 'https://client.alocubatravel.com/order-tracking/{id}',
    AUTH_URL: "{HOST}/authenticate",
    GET_SELLERS_URL: "{HOST}/seller?page={page}&pageSize={pageSize}&filter={filter}&includeCurrent={includeCurrent}&agencyId={agencyId}",
    FIND_SELLER_URL: "{HOST}/seller/{id}",
    CREATE_SELLER_URL: "{HOST}/seller",
    UPDATE_SELLER_URL: "{HOST}/seller/{id}",
    SET_SELLER_ACTIVE_URL: "{HOST}/seller/{id}/setActive",
    SET_SELLER_INACTIVE_URL: "{HOST}/seller/{id}/setInactive",
    REMOVE_SELLER_URL: "{HOST}/seller/{id}",
    GET_PRODUCTS_URL: "{HOST}/product-order/topup/product",
    GET_TOPUP_PRICES_URL: "{HOST}/product-order/topup/price",
    CREATE_TOPUP_PRICE_URL: "{HOST}/product-order/topup/price",
    UPDATE_TOPUP_PRICE_URL: "{HOST}/product-order/topup/price/{id}",
    REMOVE_TOPUP_PRICE_URL: "{HOST}/product-order/topup/price/{id}",
    VALIDATE_TOPUP_URL: "{HOST}/product-order/topup/validate",
    DO_TOPUP_URL: "{HOST}/product-order/topup",
    CONFIRM_TOPUP_URL: "{HOST}/product-order/topup/{id}/confirm",
    GET_TOPUPS_URL: "{HOST}/product-order/topup?page={page}&pageSize={pageSize}&clientId={clientId}&contactId={contactId}&geoId={geoId}&from={from}&to={to}&status={status}&agencyId={agencyId}&filter={filter}",
    
    // CLIENTS
    GET_CLIENTS_URL: "{HOST}/client?page={page}&pageSize={pageSize}&filter={filter}&agencyId={agencyId}",
    FIND_CLIENT_URL: "{HOST}/client/{id}",
    CREATE_CLIENT_URL: "{HOST}/client",
    UPDATE_CLIENT_URL: "{HOST}/client/{id}",
    REMOVE_CLIENT_URL: "{HOST}/client/{id}",

    // CONTACTS
    GET_CLIENT_CONTACT_URL: "{HOST}/client/{id}/contact?page={page}&pageSize={pageSize}&filter={filter}&agencyId={agencyId}",
    FIND_CLIENT_CONTACT_URL: "{HOST}/client/{id}/contact/{contactId}",
    CREATE_CLIENT_CONTACT_URL: "{HOST}/client/{id}/contact",
    UPDATE_CLIENT_CONTACT_URL: "{HOST}/client/{id}/contact/{contactId}",
    REMOVE_CLIENT_CONTACT_URL: "{HOST}/client/{id}/contact/{contactId}",
    SEARCH_CLIENT_CONTACT_URL: "{HOST}/client-contact/search?filter={filter}&clientId={clientId}",
    PASSWORD_VALIDATE_CODE_URL: "{HOST}/validate-password-code",
    PASSWORD_VALIDATE_DELIVERY_PERSON_CODE_URL: "{HOST}/validate-password-code/delivery-person",
    CHANGE_PASSWORD_URL: "{HOST}/change-password",
    CHANGE_PASSWORD_DELIVERY_PERSON_URL: "{HOST}/change-password/delivery-person",
    GET_ROLE_URL: "{HOST}/role",
    GET_ALL_ACCESS_URL: "{HOST}/access",
    GET_NEW_ACCESS_VALUE_URL: "{HOST}/access/next-value",
    GET_ROLE_ACCESS_URL: "{HOST}/role/{id}/access",
    TOGGLE_ROLE_ACCESS_URL: "{HOST}/role/{id}/toggle-access/{access}",
    RECOVER_PASSWORD_URL: "{HOST}/recover-password",
    GET_AGENCY_URL: "{HOST}/agency?page={page}&pageSize={pageSize}&filter={filter}&includeChildren={includeChildren}&includeCurrent={includeCurrent}",
    CREATE_AGENCY_URL: "{HOST}/agency",
    UPDATE_AGENCY_URL: "{HOST}/agency/{id}",
    FIND_AGENCY_URL: "{HOST}/agency/{id}",
    REMOVE_AGENCY_URL: "{HOST}/agency/{id}",
    SET_AGENCY_SALE_PRICE_URL: "{HOST}/agency/{agencyId}/sale-price/{salePriceId}",
    DOWNLOAD_AGENCY_ORDER_REPORT_URL: "{HOST}/agency/{id}/order-report?from={from}&to={to}",
    DOWNLOAD_SELLER_SALES_REPORT_URL: "{HOST}/seller/{id}/sales-report?from={from}&to={to}",
    UPDATE_AGENCY_MAIN_USER_PASSWORD_URL: "{HOST}/agency/{id}/update-main-user-password",
    UPDATE_DELIVERY_PERSON_PASSWORD_URL: "{HOST}/delivery-person/{id}/update-password",
    UPDATE_SELLER_PASSWORD_URL: "{HOST}/seller/{id}/update-password",

    // ARTICLE
    GET_ARTICLE_URL: "{HOST}/article?page={page}&pageSize={pageSize}&filter={filter}",
    GET_ALL_ARTICLE_URL: "{HOST}/all-article",
    FILTER_ARTICLE_URL: "{HOST}/article/geo/{id}",
    FIND_ARTICLE_URL: "{HOST}/article/{id}",
    CREATE_ARTICLE_URL: "{HOST}/article",
    UPDATE_ARTICLE_URL: "{HOST}/article/{id}",
    REMOVE_ARTICLE_URL: "{HOST}/article/{id}",
    UPLOAD_ARTICLE_IMAGE_URL: "{HOST}/article/{id}/upload-image",

    GET_ARTICLE_PRICE_URL: "{HOST}/article/{id}/price?page={page}&pageSize={pageSize}&filter={filter}",
    FIND_ARTICLE_PRICE_URL: "{HOST}/article/{id}/price/{priceId}",
    CREATE_ARTICLE_PRICE_URL: "{HOST}/article/{id}/price",
    UPDATE_ARTICLE_PRICE_URL: "{HOST}/article/{id}/price/{priceId}",
    SEARCH_ARTICLE_PRICE_URL: "{HOST}/article/price/search?filter={filter}",
    REMOVE_ARTICLE_PRICE_URL: "{HOST}/article/price/{id}",
    GET_BULK_ARTICLE_PRICE_URL: "{HOST}/article/price",

    SEARCH_GEO_URL: "{HOST}/geo?page={page}&pageSize={pageSize}&filter={filter}&level={level}&parentIdFilter={parentIdFilter}",
    GET_ARTICLE_PROD_COST_URL: "{HOST}/article/{id}/production-cost?page={page}&pageSize={pageSize}&filter={filter}",
    FIND_ARTICLE_PROD_COST_URL: "{HOST}/article/{id}/production-cost/{productionCostId}",
    CREATE_ARTICLE_PROD_COST_URL: "{HOST}/article/{id}/production-cost",
    UPDATE_ARTICLE_PROD_COST_URL: "{HOST}/article/{id}/production-cost/{productionCostId}",
    SEARCH_ARTICLE_PROD_COST_URL: "{HOST}/article/production-cost/search?filter={filter}",
    REMOVE_ARTICLE_PROD_COST_URL: "{HOST}/article/production-cost/{id}",

    // PRODUCT ORDER
    ADD_DELIVERY_COMMENT_URL: "{HOST}/product-order/{id}/comment",
    GET_PRODUCT_ORDER_COMMENTS_URL: "{HOST}/product-order/{id}/comments",
    UPDATE_DELIVERY_OBSERVATIONS_URL: "{HOST}/product-order/{id}/observations",
    REMOVE_PRODUCT_ORDERS_URL: "{HOST}/product-orders?ids={ids}",

    // ARTICLE DELIVERY ORDER
    GET_ARTICLE_REMITTANCE_URL: "{HOST}/product-order/article-delivery?page={page}&pageSize={pageSize}&from={from}&to={to}&filter={filter}&deliveryPersonId={deliveryPersonId}&status={status}&geoId={geoId}&agencyId={agencyId}",
    FIND_ARTICLE_REMITTANCE_URL: "{HOST}/product-order/article-delivery/{id}",
    CREATE_ARTICLE_REMITTANCE_URL: "{HOST}/product-order/article-delivery",
    UPDATE_ARTICLE_REMITTANCE_URL: "{HOST}/product-order/article-delivery/{id}",
    SET_ARTICLE_REMITTANCE_DELIVERY_PERSON_URL: "{HOST}/product-order/article-delivery/delivery-person/{deliveryPersonId}",
    UNSET_ARTICLE_REMITTANCE_DELIVERY_PERSON_URL: "{HOST}/product-order/article-delivery/{articleRemitanteId}",
    REMOVE_ARTICLE_DELIVERY_ORDER_URL: "{HOST}/product-order/article-delivery/{id}",
    UPDATE_ARTICLE_DELIVERY_ORDER_STATUS_URL: "{HOST}/product-order/article-delivery/{articleDeliveryId}/status/{status}",
    UPDATE_ARTICLE_DELIVERY_ORDERS_STATUS_URL: "{HOST}/product-order/article-delivery/status/{status}",

    // ARTICLE STOCK
    GET_ARTICLE_STOCK_URL: "{HOST}/delivery-person/{personId}/article-stock?page={page}&pageSize={pageSize}",
    ADD_ARTICLE_TO_DELIVERY_PERSON_STOCK_URL: "{HOST}/delivery-person/{id}/article-stock",
    GET_PRODUCT_ORDER_STATUS_HISTORY_URL: "{HOST}/product-order/{id}/status-history",

    GET_REMITTANCE_ADDITIONAL_PRICE_URL: "{HOST}/remittance/{id}/additional-price?page={page}&pageSize={pageSize}&filter={filter}",
    FIND_REMITTANCE_ADDITIONAL_PRICE_URL: "{HOST}/remittance/{id}/additional-price/{additionalPriceId}",
    CREATE_REMITTANCE_ADDITIONAL_PRICE_URL: "{HOST}/remittance/{id}/additional-price",
    UPDATE_REMITTANCE_ADDITIONAL_PRICE_URL: "{HOST}/remittance/{id}/additional-price/{additionalPriceId}",
    SEARCH_REMITTANCE_ADDITIONAL_PRICE_URL: "{HOST}/remittance/additional-price/search?filter={filter}",
    REMOVE_REMITTANCE_ADDITIONAL_PRICE_URL: "{HOST}/remittance/additional-price/{id}",

    GET_DELIVERY_PERSON_URL: "{HOST}/delivery-person/get?page={page}&pageSize={pageSize}&filter={filter}&activeMoneyDeliveryBalances={activeMoneyDeliveryBalances}",
    GET_DELIVERY_PERSON_TOTAL_BALANCES_URL: "{HOST}/delivery-person/total-balances",
    FIND_DELIVERY_PERSON_URL: "{HOST}/delivery-person/{id}",
    CREATE_DELIVERY_PERSON_URL: "{HOST}/delivery-person",
    UPDATE_DELIVERY_PERSON_URL: "{HOST}/delivery-person/{id}",
    REMOVE_DELIVERY_PERSON_URL: "{HOST}/delivery-person/{id}",
    ADD_BALANCE_TO_DELIVERY_PERSON_URL: "{HOST}/delivery-person/{id}/balance",
    TRANSFER_BALANCE_TO_DELIVERY_PERSON_URL: "{HOST}/delivery-person/{id}/balance/transfer",

    GET_MONEY_EXCHANGE_URL: "{HOST}/money-exchange-rate?page={page}&pageSize={pageSize}&agencyId={agencyId}&filter={filter}",
    FIND_MONEY_EXCHANGE_URL: "{HOST}/money-exchange-rate/{id}",
    CREATE_MONEY_EXCHANGE_URL: "{HOST}/money-exchange-rate",
    UPDATE_MONEY_EXCHANGE_URL: "{HOST}/money-exchange-rate/{id}",
    REMOVE_MONEY_EXCHANGE_URL: "{HOST}/money-exchange-rate/{id}",

    GET_MONEY_DELIVERY_FEE_URL: "{HOST}/money-delivery-fee?page={page}&pageSize={pageSize}&filter={filter}",
    GET_MONEY_DELIVERY_FEE_FROM_BASE_AGENCY_URL: "{HOST}/money-delivery-fee/from-base-agency",
    FIND_MONEY_DELIVERY_FEE_URL: "{HOST}/money-delivery-fee/{id}",
    CREATE_MONEY_DELIVERY_FEE_URL: "{HOST}/money-delivery-fee",
    UPDATE_MONEY_DELIVERY_FEE_URL: "{HOST}/money-delivery-fee/{id}",
    REMOVE_MONEY_DELIVERY_FEE_URL: "{HOST}/money-delivery-fee/{id}",

    GET_DELIVERY_COST_URL: "{HOST}/delivery-person/{personId}/delivery-cost?page={page}&pageSize={pageSize}&currency={currency}&geoId={geoId}",
    FIND_DELIVERY_COST_URL: "{HOST}/delivery-cost/{id}",
    UPDATE_DELIVERY_COST_URL: "{HOST}/delivery-cost/{id}",
    CREATE_DELIVERY_COST_URL: "{HOST}/delivery-person/{personId}/delivery-cost",
    REMOVE_DELIVERY_COST_URL: "{HOST}/delivery-person/{personId}/delivery-cost/{id}",

    GET_TRANSACTION_URL: "{HOST}/transaction/{id}?page={page}&pageSize={pageSize}&from={from}&to={to}&status={status}&currency={currency}",
    GET_ARTICLE_TRANSACTION_URL: "{HOST}/article-transaction/{id}?page={page}&pageSize={pageSize}&from={from}&to={to}&status={status}&articleId={articleId}",

    GET_MONEY_REMITTANCE_URL: "{HOST}/product-order/money-delivery?page={page}&pageSize={pageSize}&from={from}&to={to}&filter={filter}&deliveryPersonId={deliveryPersonId}&status={status}&currency={currency}&type={type}&geoIds={geoIds}&agencyId={agencyId}",
    GET_POSSIBLY_REPEATED_MONEY_REMITTANCE_URL: "{HOST}/product-order/money-delivery/possibly-repeated?clientId={clientId}&contactId={contactId}&currency={currency}&amountToReceive={amountToReceive}&contactBankCardId={contactBankCardId}",
    FIND_MONEY_REMITTANCE_URL: "{HOST}/product-order/money-delivery/{id}",
    CREATE_MONEY_REMITTANCE_URL: "{HOST}/product-order/money-delivery",
    GET_MONEY_DELIVERY_BUDGET: "{HOST}/product-order/money-delivery/budget?currency={currency}&totalToSend={totalToSend}&totalToReceive={totalToReceive}&totalToPay={totalToPay}&agencyId={agencyId}",
    REMOVE_MONEY_DELIVERY_ORDER_URL: "{HOST}/product-order/money-delivery/{id}",
    SET_MONEY_REMITTANCE_DELIVERY_PERSON_URL: "{HOST}/product-order/money-delivery/delivery-person/{deliveryPersonId}",
    UPDATE_MONEY_REMITTANCE_CONTACT_URL: "{HOST}/product-order/money-delivery/{moneyRemitanteId}/contact/{contactId}",
    UNSET_MONEY_REMITTANCE_DELIVERY_PERSON_URL: "{HOST}/product-order/money-delivery/{moneyRemitanteId}",
    UPDATE_MONEY_DELIVERY_ORDER_STATUS_URL: "{HOST}/product-order/money-delivery/{moneyDeliveryId}/status/{status}",
    UPDATE_MONEY_DELIVERY_ORDERS_STATUS_URL: "{HOST}/product-order/money-delivery/status/{status}",
    UPDATE_MONEY_DELIVERY_ORDER_VALUES_URL: "{HOST}/product-order/money-delivery/{moneyDeliveryId}/values",

    GET_MONEY_INVOICE_TO_DELIVER: "{HOST}/product-order/money-delivery/invoice-to-deliver",

    GET_PACKAGE_DELIVERY_URL: "{HOST}/product-order/package-delivery?page={page}&pageSize={pageSize}&from={from}&to={to}&filter={filter}&deliveryPersonId={deliveryPersonId}&status={status}&geoId={geoId}&agencyId={agencyId}&shippingId={shippingId}&shipmentAssigned={shipmentAssigned}",
    FIND_PACKAGE_DELIVERY_URL: "{HOST}/product-order/package-delivery/{id}",
    CREATE_PACKAGE_DELIVERY_URL: "{HOST}/product-order/package-delivery",
    GET_PACKAGE_DELIVERY_BUDGET: "{HOST}/product-order/package-delivery/budget?currency={currency}&totalToSend={totalToSend}&totalToReceive={totalToReceive}&totalToPay={totalToPay}&agencyId={agencyId}",
    REMOVE_PACKAGE_DELIVERY_ORDER_URL: "{HOST}/product-order/package-delivery/{id}",
    UPDATE_PACKAGE_DELIVERY_SHIPPING_ID_URL: "{HOST}/product-order/package-delivery/shipping/{shippingId}",
    GET_PACKAGE_DELIVERY_SHIPPING_ID_URL: "{HOST}/product-order/package-delivery/shipping-number",
    SET_PACKAGE_DELIVERY_DELIVERY_PERSON_URL: "{HOST}/product-order/package-delivery/delivery-person/{deliveryPersonId}",
    UPDATE_PACKAGE_DELIVERY_CONTACT_URL: "{HOST}/product-order/package-delivery/{packageDeliveryId}/contact/{contactId}",
    UNSET_PACKAGE_DELIVERY_DELIVERY_PERSON_URL: "{HOST}/product-order/package-delivery/{packageDeliveryId}",
    UPDATE_PACKAGE_DELIVERY_ORDER_STATUS_URL: "{HOST}/product-order/package-delivery/{packageDeliveryId}/status/{status}",
    UPDATE_PACKAGE_DELIVERY_ORDERS_STATUS_URL: "{HOST}/product-order/package-delivery/status/{status}",
    GET_PRODUCT_ORDER_DELIVERY_PERSONS_INFO: "{HOST}/product-order/package-deliveries/delivery-persons-info", //PUT
    ADD_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO: "{HOST}/product-order/package-deliveries/delivery-persons-info", //POST
    REMOVE_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO: "{HOST}/product-order/package-deliveries/delivery-persons-info/{deliveryPersonId}",//POST
    SET_COMMISSION_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO: "{HOST}/product-order/package-deliveries/delivery-persons-info/{deliveryPersonId}", //PUT
    CONFIRM_RECEIVED_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO: "{HOST}/product-order/package-deliveries/delivery-persons-info/{deliveryPersonId}/received",//POST
    CONFIRM_DELIVERED_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO: "{HOST}/product-order/package-deliveries/delivery-persons-info/{deliveryPersonId}/delivered",
    UPDATE_ITEMS_AND_PAYMENTS_PACKAGE_DELIVERY_URL:"{HOST}/product-order/package-delivery/{packageDeliveryId}/items-and-payments",//POST

    GET_INVOICE_URL: "{HOST}/invoice?page={page}&pageSize={pageSize}&from={from}&to={to}&status={status}&filter={filter}&clientId={clientId}",
    FIND_INVOICE_BY_PRODUCT_ORDER_URL: "{HOST}/product-order/{productOrderId}/invoice",
    PAY_INVOICE_URL: "{HOST}/product-order/{productOrderId}/payments",

    GET_PAYMENT_FEE_URL: "{HOST}/payment/fee?agencyId={agencyId}",
    UPDATE_PAYMENT_FEE_URL: "{HOST}/payment/fee?agencyId={agencyId}",

    GET_CONTACT_BANK_CARD_URL: "{HOST}/contact/{contactId}/bank-card?page={page}&pageSize={pageSize}&filter={filter}",
    FIND_CONTACT_BANK_CARD_URL: "{HOST}/contact/{contactId}/bank-card/{bankCardId}",
    CREATE_CONTACT_BANK_CARD_URL: "{HOST}/contact/{contactId}/bank-card",
    UPDATE_CONTACT_BANK_CARD_URL: "{HOST}/contact/{contactId}/bank-card/{id}",
    SEARCH_CONTACT_BANK_CARD_URL: "{HOST}/contact/bank-card/search?filter={filter}",
    REMOVE_CONTACT_BANK_CARD_URL: "{HOST}/contact/{contactId}/bank-card/{id}",

    GET_PACKAGE_CATEGORY_COST_URL: "{HOST}/package-category/{id}/cost?page={page}&pageSize={pageSize}&geoId={geoId}",
    FIND_PACKAGE_CATEGORY_COST_URL: "{HOST}/package-category-cost/{id}",
    CREATE_PACKAGE_CATEGORY_COST_URL: "{HOST}/package-category-cost",
    UPDATE_PACKAGE_CATEGORY_COST_URL: "{HOST}/package-category-cost/{id}",
    REMOVE_PACKAGE_CATEGORY_COST_URL: "{HOST}/package-category-cost/{id}",

    GET_PACKAGE_CATEGORY_URL: "{HOST}/package-category?page={page}&pageSize={pageSize}&filter={filter}",
    FIND_PACKAGE_CATEGORY_URL: "{HOST}/package-category/{id}",
    CREATE_PACKAGE_CATEGORY_URL: "{HOST}/package-category",
    GET_SALES_BY_PAYMENT_TYPE_URL: "{HOST}/sales/by-payment-type?from={from}&to={to}&agencyId={agencyId}&userId={userId}",
    GET_SALES_BY_SERVICE_URL: "{HOST}/sales/by-service?from={from}&to={to}&agencyId={agencyId}&userId={userId}&includeChildren={includeChildren}",
    GET_SALES_BY_USER_URL: "{HOST}/sales/by-user?from={from}&to={to}&agencyId={agencyId}",
    GET_SALES_BY_AGENCY_URL: "{HOST}/sales/by-agency?from={from}&to={to}&agencyId={agencyId}",
    GET_MONEY_DELIVERY_SALES_URL: "{HOST}/sales/money-delivery?from={from}&to={to}&agencyId={agencyId}&userId={userId}",
    GET_TOPUP_SALES_URL: "{HOST}/sales/topup?from={from}&to={to}&agencyId={agencyId}&userId={userId}",
    GET_ARTICLE_SALES_URL: "{HOST}/sales/article?from={from}&to={to}&agencyId={agencyId}&userId={userId}",
    GET_MISCELLANY_SALES_URL: "{HOST}/sales/miscellany?from={from}&to={to}&agencyId={agencyId}&userId={userId}",
    GET_MONEY_DELIVERY_ORDER_OVERVIEW_URL: "{HOST}/product-order/money-delivery-overview?from={from}&to={to}&filter={filter}&deliveryPersonId={deliveryPersonId}&status={status}&currency={currency}&type={type}&geoId={geoId}&agencyId={agencyId}&includeChildren={includeChildren}",
    GET_PACKAGE_DELIVERY_ORDER_OVERVIEW_URL: "{HOST}/product-order/package-delivery-overview?from={from}&to={to}&filter={filter}&deliveryPersonId={deliveryPersonId}&status={status}&geoId={geoId}&agencyId={agencyId}&shippingId={shippingId}&includeChildren={includeChildren}",
    DOWNLOAD_MONEY_DELIVERY_ORDER_CSV_URL: "{HOST}/product-order/money-delivery/csv?from={from}&to={to}&filter={filter}&deliveryPersonId={deliveryPersonId}&status={status}&currency={currency}&type={type}&geoId={geoId}&agencyId={agencyId}",

    GET_SALE_PRICE_URL: "{HOST}/sale-price?page={page}&pageSize={pageSize}&filter={filter}",
    GET_SALE_PRICE_FROM_AGENCY__URL: "{HOST}/sale-price/from-agency",
    FIND_SALE_PRICE_URL: "{HOST}/sale-price/{id}",
    CREATE_SALE_PRICE_URL: "{HOST}/sale-price",
    UPDATE_SALE_PRICE_URL: "{HOST}/sale-price/{id}",
    SET_SALE_PRICE_ACTIVE_URL: "{HOST}/sale-price/{id}/setActive",
    SET_SALE_PRICE_INACTIVE_URL: "{HOST}/sale-price/{id}/setInactive",
    REMOVE_SALE_PRICE_URL: "{HOST}/sale-price/{id}",
    // Products sale prices
    UPDATE_ARTICLE_SALE_PRICE_URL: "{HOST}/sale-price/{id}/article/{articleId}",
    UPDATE_MONEY_SALE_PRICE_URL: "{HOST}/sale-price/{id}/money/{serviceFeeId}",
    UPDATE_TOPUP_SALE_PRICE_URL: "{HOST}/sale-price/{id}/topup/{productId}",
    UPDATE_REFERENCE_ITEM_SALE_PRICE_URL:"{HOST}/sale-price/{id}/package-reference-item/{referenceItemId}",
    UPDATE_MISCELLANY_SALE_PRICE_URL: "{HOST}/sale-price/{id}/miscellany-category/{miscellanyCategoryId}",

    // Custom Category
    GET_CUSTOM_CATEGORY_URL:"{HOST}/custom-category?page={page}&pageSize={pageSize}&filter={filter}",
    CREATE_CUSTOM_CATEGORY_URL:"{HOST}/custom-category",
    FIND_CUSTOM_CATEGORY_URL:"{HOST}/custom-category/{id}",
    UPDATE_CUSTOM_CATEGORY_URL:"{HOST}/custom-category/{id}",
    REMOVE_CUSTOM_CATEGORY_URL:"{HOST}/custom-category/{id}",
    // Custom Category Weight Ranges
    CREATE_CUSTOM_CATEGORY_WEIGHT_RANGE_URL:"{HOST}/custom-category/{customCategoryId}/weight-range",
    FIND_CUSTOM_CATEGORY_WEIGHT_RANGE_URL:"{HOST}/custom-category/{customCategoryId}/weight-range/{weightRangeId}",
    UPDATE_CUSTOM_CATEGORY_WEIGHT_RANGE_URL:"{HOST}/custom-category/{customCategoryId}/weight-range/{weightRangeId}",
    REMOVE_CUSTOM_CATEGORY_WEIGHT_RANGE_URL:"{HOST}/custom-category/{customCategoryId}/weight-range/{weightRangeId}",

    // GET_PRICE_FROM_CUSTOM_CATEGORY_URL
    GET_PRICE_FROM_CUSTOM_CATEGORY_URL:"{HOST}/custom-category/{customCategoryId}/weight-range?weight={weight}&deliveryType={deliveryType}",

    // Inventory Item
    GET_INVENTORY_ITEM_URL:"{HOST}/inventory-item?page={page}&pageSize={pageSize}&filter={filter}&customCategoryId={customCategoryId}",
    CREATE_INVENTORY_ITEM_URL:"{HOST}/inventory-item",
    FIND_INVENTORY_ITEM_URL:"{HOST}/inventory-item/{id}",
    UPDATE_INVENTORY_ITEM_URL:"{HOST}/inventory-item/{id}",
    REMOVE_INVENTORY_ITEM_URL:"{HOST}/inventory-item/{id}",

    // Reference Item
    GET_REFERENCE_ITEM_URL:"{HOST}/reference-item?page={page}&pageSize={pageSize}&filter={filter}",
    CREATE_REFERENCE_ITEM_URL:"{HOST}/reference-item",
    FIND_REFERENCE_ITEM_URL:"{HOST}/reference-item/{id}",
    UPDATE_REFERENCE_ITEM_URL:"{HOST}/reference-item/{id}",
    REMOVE_REFERENCE_ITEM_URL:"{HOST}/reference-item/{id}",

    //Reference Item Price
    GET_REFERENCE_ITEM_PRICE_URL:"{HOST}/reference-item-price?page={page}&pageSize={pageSize}&filter={filter}",
    CREATE_REFERENCE_ITEM_PRICE_URL:"{HOST}/reference-item-price",
    FIND_REFERENCE_ITEM_PRICE_URL:"{HOST}/reference-item-price/{id}",
    UPDATE_REFERENCE_ITEM_PRICE_URL:"{HOST}/reference-item-price/{referenceItemId}/amount/{amount}",
    REMOVE_REFERENCE_ITEM_PRICE_URL:"{HOST}/reference-item-price/{id}",
    GET_REFERENCE_ITEM_PRICE_FROM_BASE_AGENCY_URL: "{HOST}/reference-item-price/from-base-agency",


    //Product Orders ToPrint
    FIND_PRODUCT_ORDER_TO_PRINT_URL: "{HOST}/product-order/print/{id}",

    // NOTIFICATIONS
    GET_USER_NOTIFICATIONS_URL: "{HOST}/notification",
    UPDATE_USER_NOTIFICATIONS_STATUS_URL: "{HOST}/notification",
    REMOVE_NOTIFICATION_URL: "{HOST}/notification/{id}",

    // PROMOTIONS
    GET_PROMOTIONS_URL: "{HOST}/promotion?page={page}&pageSize={pageSize}&filter={filter}",
    GET_ACTIVE_PROMOTIONS_URL: "{HOST}/promotion/active",
    FIND_PROMOTION_URL: "{HOST}/promotion/{id}",
    CREATE_PROMOTION_URL: "{HOST}/promotion",
    UPDATE_PROMOTION_URL: "{HOST}/promotion/{id}",
    REMOVE_PROMOTION_URL: "{HOST}/promotion/{id}",
    UPLOAD_PROMOTION_IMAGE_URL: "{HOST}/promotion/{id}/upload-image",

    // MISCELLANY_CATEGORY
    GET_MISCELLANY_CATEGORY_URL: "{HOST}/miscellany-category?page={page}&pageSize={pageSize}&filter={filter}&agencyId={agencyId}",
    FIND_MISCELLANY_CATEGORY_URL: "{HOST}/miscellany-category/{id}",
    CREATE_MISCELLANY_CATEGORY_URL: "{HOST}/miscellany-category",
    UPDATE_MISCELLANY_CATEGORY_URL: "{HOST}/miscellany-category/{id}",
    REMOVE_MISCELLANY_CATEGORY_URL: "{HOST}/miscellany-category/{id}",
    UPDATE_MISCELLANY_CATEGORY_PRICE_URL: "{HOST}/product-order/miscellany-category/{id}/price",

    // MISCELLANY ORDER
    GET_MISCELLANY_ORDER_URL: "{HOST}/product-order/miscellany?page={page}&pageSize={pageSize}&from={from}&to={to}&filter={filter}&status={status}&agencyId={agencyId}",
    CREATE_MISCELLANY_ORDER_URL: "{HOST}/product-order/miscellany",
    UPDATE_MISCELLANY_ORDER_STATUS_URL: "{HOST}/product-order/miscellany/{id}/status/{status}",
    UPDATE_MISCELLANY_ORDERS_STATUS_URL: "{HOST}/product-order/miscellany/status/{status}",
    REMOVE_MISCELLANY_ORDER_URL: "{HOST}/product-order/miscellany/{id}",

    // SHOP PRODUCT
    GET_SHOP_PRODUCT_URL: "{HOST}/shop-product?page={page}&pageSize={pageSize}&filter={filter}",
    GET_ALL_SHOP_PRODUCT_URL: "{HOST}/all-shop-products",
    FIND_SHOP_PRODUCT_URL: "{HOST}/shop-product/{id}",
    CREATE_SHOP_PRODUCT_URL: "{HOST}/shop-product",
    UPDATE_SHOP_PRODUCT_URL: "{HOST}/shop-product/{id}",
    REMOVE_SHOP_PRODUCT_URL: "{HOST}/shop-product/{id}",
    UPLOAD_SHOP_PRODUCT_IMAGE_URL: "{HOST}/shop-product/{id}/upload-image",
    GET_SHOP_PRODUCT_STOCK_TRANSACTION_URL: "{HOST}/shop-product/{id}/stock-transaction?page={page}&pageSize={pageSize}&from={from}&to={to}",

    // SHOP ORDER
    GET_SHOP_ORDER_URL: "{HOST}/product-order/shop?page={page}&pageSize={pageSize}&from={from}&to={to}&filter={filter}&status={status}&agencyId={agencyId}",
    CREATE_SHOP_ORDER_URL: "{HOST}/product-order/shop",
    REMOVE_SHOP_ORDER_URL: "{HOST}/product-order/shop/{id}",
};


export default values;
