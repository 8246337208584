import React from "react";
import DynamicTabsProperties from "./DynamicTabsProperties";
import {Observer} from "mobx-react-lite";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@material-ui/core";
import CategoryWithItems from "../../../pages/CustomCategory/CustomCategoryRow/CategoryWithItems/CategoryWithItems";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import {useI18nContext} from "../../../contexts/I18nContext";
import {ShoppingBasket} from "@material-ui/icons";
import { useStyles } from "./DynamicTabs.style";

const DynamicTabs: React.FC<DynamicTabsProperties> = ({
                                                         customCategories,
                                                         selectedCustomCategory,
                                                         onCustomCategorySelectChange,
                                                         itemsAndCategories,
                                                         onRemovePackageItem,
                                                         onEditPackageItem,
                                                         onFinalIndividualPriceChange,
                                                         onWeightChange,
                                                         onFinalWeightPriceChange,
                                                         onFinalPriceChange
                                                     })=>{
    const i18n = useI18nContext();
    const classes = useStyles()
    const handleChange = (event:any, newValue:string) => {
        onCustomCategorySelectChange(newValue)
    }

    return <Observer>
        {()=> (
            <div className={classes.tabs_container}>
                <TabContext value={selectedCustomCategory?.id || customCategories[0]?.id || "0"}>
                    <TabList  className={classes.tabs_container} onChange={handleChange} orientation={"horizontal"}>
                        {customCategories.map(category => {
                            const correspondingItem = itemsAndCategories.find(item => item.categoryInfo.id === category.id);
                            return <Tab key={category.id}
                                label={correspondingItem ? `${category.name} (${correspondingItem?.packageItem.length})` : category.name}
                                value={category.id} style={{width: "100%"}}
                            />
                        })}
                    </TabList>
                    {customCategories.map(category => {
                        const correspondingItem = itemsAndCategories
                            .find(item => item.categoryInfo.id === category.id);
                        return (
                            <TabPanel key={category.id} value={category.id}>
                                {correspondingItem ? (
                                    <CategoryWithItems
                                        categoryWithItems={correspondingItem}
                                        onRemovePackageItem={onRemovePackageItem}
                                        onEditPackageItem={onEditPackageItem}
                                        onFinalIndividualPriceChange={onFinalIndividualPriceChange}
                                        onWeightChange={onWeightChange}
                                        onFinalWeightPriceChange={onFinalWeightPriceChange}
                                        onFinalPriceChange={onFinalPriceChange}
                                    />
                                ) : (
                                    <MessageCard icon={ShoppingBasket} title={i18n.translate("NO_PACKAGE_DELIVERY_ITEM_TITLE") + category.name + i18n.translate("NO_PACKAGE_DELIVERY_ITEM2_TITLE")}
                                                 subtitle={i18n.translate("NO_PACKAGE_DELIVERY_TITLE_ITEM_SUBTITLE")}/>
                                )}
                            </TabPanel>

                        );
                    })}
                </TabContext>
            </div>
        )}
    </Observer>
}
export default DynamicTabs