import React from 'react';
import CustomCategoryWeightRangesButtonProperties from "./CustomCategoryWeightRangesButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import CustomCategoryWeightRangesStore from "./CustomCategoryWeightRangesStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./CustomCategoryWeightRangesButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import SidePanel from '../../../ui_components/SidePanel/SidePanel';
import ShowSellerBasicInfo from '../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo';
import NewCustomCategoryWeightRangeButton from '../NewCustomCategoryWeightRangeButton/NewCustomCategoryWeightRangeButton';
import ShowIntervalWithTitle from '../../Info/ShowIntervalWithTitle/ShowIntervalWithTitle';
import ShowText from '../../Info/ShowText/ShowText';
import { PackageDeliveryType } from '../../../model/packageDelivery/PackageDeliveryType';
import ScaleIcon from '@mui/icons-material/Scale';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import RemoveCustomCategoryWeightRangeButton from '../RemoveCustomCategoryWeightRangeButton/RemoveCustomCategoryWeightRangeButton';
import Currency from '../../../entities/Currency';
import { CurrencyModule } from '../../../util/Currency';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';

const CustomCategoryWeightRangesButton: React.FC<CustomCategoryWeightRangesButtonProperties> = ({
                                                                          onClick = () => {
                                                                          },
                                                                          onSave = () => {
                                                                          },
                                                                          showIcon = true,
                                                                          showText = true,
                                                                          disabled,
                                                                          className = '',
                                                                          customCategoryId,
                                                                          rounded = false,
                                                                          size = ButtonSize.DEFAULT,
                                                                          type = ButtonType.DEFAULT
                                                                      }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new CustomCategoryWeightRangesStore(commonContext, customCategoryId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onUpdate = async () => {
        await store.loadCustomCategory();
    }

    const getDeliveryTypeIcon = (deliveryType: string) => {
        switch (deliveryType) {
            case PackageDeliveryType[PackageDeliveryType.PLANE]:
                return <AirplanemodeActiveIcon></AirplanemodeActiveIcon>;
            case PackageDeliveryType[PackageDeliveryType.SHIP]:
                return <DirectionsBoatIcon></DirectionsBoatIcon>;
            case PackageDeliveryType[PackageDeliveryType.EXPRESS]:
                return <AccessAlarmsIcon></AccessAlarmsIcon>;
            case PackageDeliveryType[PackageDeliveryType.SEMI_EXPRESS]:
                return <AddAlarmIcon></AddAlarmIcon>;
            default:
                return <></>;
        }
    }

    const getDeliveryTypeText = (deliveryType: string) => {
        switch (deliveryType) {
            case PackageDeliveryType[PackageDeliveryType.PLANE]:
                return 'Avión';
            case PackageDeliveryType[PackageDeliveryType.SHIP]:
                return 'Barco';
            case PackageDeliveryType[PackageDeliveryType.EXPRESS]:
                return 'Express';
            case PackageDeliveryType[PackageDeliveryType.SEMI_EXPRESS]:
                return 'Semi Express';
            default:
                return <></>
        }
    }

    const text = 'Rango de pesajes';
    const Icon = ScaleIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}
                    className={classes.modal_container}>
                    <div className={classes.contacts_container}>
                        <Box
                            width={Width.FULL_WIDTH}
                            justifyContent={Alignment.SPACE_BETWEEN}>
                            <ShowSellerBasicInfo autofill name={store.customCategory.name}/>
                            {/* {userStore.hasAccess(Access.CREATE_EDIT_WEIGHT_RANGE) &&
                                
                            } */}
                            <NewCustomCategoryWeightRangeButton
                                type={ButtonType.SECONDARY}
                                onSave={onUpdate}
                                customCategoryId={customCategoryId}
                                size={ButtonSize.SMALL}
                                showText/>
                        </Box>
                        <div className={classes.body}>
                            {!store.isEmpty &&
                            <div className={classes.contacts_table}>
                                <div className={classes.elements_container}>
                                    {Object.keys(PackageDeliveryType)
                                        .filter((key) => isNaN(Number(key))).map((deliveryType, i) => (
                                        <div>
                                            <div className={classes.delivery_type_header}>
                                                <div>
                                                    {getDeliveryTypeIcon(deliveryType)}
                                                </div>
                                                <div>{getDeliveryTypeText(deliveryType)}</div>
                                            </div>
                                            {store.customCategory.weightRanges
                                                .filter(t => t.deliveryType.toString() === deliveryType).map((item, index) => (
                                                <div className={classes.contact_info_container}>
                                                    <ShowIntervalWithTitle title={i18n.translate("WEIGHT_RANGES_RANGE")} from={item.from} to={item.to}/>
                                                    <ShowText text={CurrencyModule.format(item.price, Currency.DOLLAR)} title={i18n.translate("WEIGHT_RANGES_PRICE")}/>
                                                    <ExpandoMenu>
                                                        <>
                                                            <NewCustomCategoryWeightRangeButton
                                                                type={ButtonType.SECONDARY}
                                                                onSave={onUpdate}
                                                                customCategoryId={customCategoryId}
                                                                weightRangeId={item.id}
                                                                size={ButtonSize.SMALL}
                                                            />
                                                            <RemoveCustomCategoryWeightRangeButton
                                                                type={ButtonType.SECONDARY}
                                                                onSave={onUpdate}
                                                                customCategoryId={customCategoryId}
                                                                weightRangeId={item.id}
                                                                size={ButtonSize.SMALL}
                                                            />
                                                        </>
                                                    </ExpandoMenu>
                                                </div>
                                            ))}
                                            {store.customCategory.weightRanges
                                                .filter(t => t.deliveryType.toString() === deliveryType).length === 0 && 
                                                <MessageCard className={classes.empty_delivery_type} title={'No hay precios para este tipo de envío!!!'}/>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            }
                            {store.isEmpty &&
                            <MessageCard icon={AttachMoneyIcon} title={i18n.translate("NO_ARTICLE_PRICES_TITLE")}
                                         subtitle={i18n.translate("NO_ARTICLE_PRICES_SUBTITLE")}/>}
                        </div>
                    </div>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default CustomCategoryWeightRangesButton;
