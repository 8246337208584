import React from "react";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useStyles} from "./NewCustomCategoryWeightRangeButton.style"
import NewCustomCategoryWeightRangeButtonProperties from "./NewCustomCategoryWeightRangeButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useCommonContext} from "../../../contexts/CommonContext";
import {useUserContext} from "../../../contexts/UserContext";
import NewCustomCategoryWeightRangeButtonStore from "./NewCustomCategoryWeightRangeButtonStore";
import IconName from "../../../ui_components/Icon/enums/IconName";
import Icon from "../../../ui_components/Icon/Icon";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Button from "../../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import Modal from "../../../ui_components/Modal/Modal";
import TextInputType from "../../../ui_components/TextInput/TextInputType";
import TextInput from "../../../ui_components/TextInput/TextInput";
import PackageDeliveryTypeSelect from "../../Selects/PackageDeliveryTypeSelect/PackageDeliveryTypeSelect";
import { PackageDeliveryType } from "../../../model/packageDelivery/PackageDeliveryType";

const NewCustomCategoryWeightRangeButton:React.FC<NewCustomCategoryWeightRangeButtonProperties> = ({
                                                                                            onClick = () => {
                                                                                            },
                                                                                            onSave = () => {
                                                                                            },
                                                                                            showIcon = true,
                                                                                            showText = true,
                                                                                            disabled,
                                                                                            className = '',
                                                                                            weightRangeId,
                                                                                            customCategoryId,
                                                                                            rounded = false,
                                                                                            size = ButtonSize.DEFAULT,
                                                                                            type = ButtonType.DEFAULT
                                                                                        }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new NewCustomCategoryWeightRangeButtonStore(commonContext, customCategoryId, weightRangeId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onFromValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFromValue = event.target.value;
        store.weightRange.from = +newFromValue;
    }

    const onToValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newToValue =  event.target.value;
        store.weightRange.to = +newToValue;
    }

    const onPriceValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newPriceValue = event.target.value;
        store.weightRange.price = +newPriceValue;
    }

    const onWeightRangeSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const onTypeChange = (newType: PackageDeliveryType) => {
        store.weightRange.deliveryType = newType;
    }

    const text = i18n.translate((store.isEditing)? "EDIT_WEIGHT_RANGE":"CREATE_WEIGHT_RANGE");
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/>: <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText? text:""}
                text={showText?text:""}
                type={type}
                icon={showIcon? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }

    return <Observer>
        { () => (
            <>
                <Modal
                    fullScreen={false}
                    loading={store.loading}
                    title={text}
                    onClose={onCloseModal}
                    open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <PackageDeliveryTypeSelect 
                            selected={store.weightRange?.deliveryType}
                            onChange={onTypeChange}
                            className={classes.input}
                            fullWidth
                        />
                        <TextInput
                            type={TextInputType.NUMBER}
                            value={store.weightRange?.from}
                            onChange={onFromValueChange}
                            error={i18n.translate(store.fromError)}
                            label={i18n.translate("WEIGHT_RANGES_FROM")}
                            className={classes.input}
                            disabled={!userStore.isRootAgency()}
                            fullWidth
                        />
                        <TextInput
                            type={TextInputType.NUMBER}
                            value={store.weightRange?.to}
                            onChange={onToValueChange}
                            error={i18n.translate(store.toError)}
                            label={i18n.translate("WEIGHT_RANGES_TO")}
                            className={classes.input}
                            disabled={!userStore.isRootAgency()}
                            fullWidth
                        />
                        <TextInput
                            type={TextInputType.NUMBER}
                            value={store.weightRange?.price}
                            onChange={onPriceValueChange}
                            error={i18n.translate(store.priceError)}
                            label={i18n.translate("WEIGHT_RANGES_PRICE")}
                            className={classes.input}
                            disabled={!userStore.isRootAgency()}
                            fullWidth
                        />
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onWeightRangeSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewCustomCategoryWeightRangeButton