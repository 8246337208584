import React from 'react';
import PackageDeliveryResumeProperties from "./PackageDeliveryResumeProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from './PackageDeliveryResume.style';
import ShowPackageItemsDeliveryDetails from '../../../Info/ShowPackageItemsDeliveryDetails/ShowPackageItemsDeliveryDetails';
import PaymentsTable from '../../../Info/PaymentsTable/PaymentsTable';


const PackageDeliveryResume: React.FC<PackageDeliveryResumeProperties> = ({
                                                                              store,
                                                                          }) => {
    const classes = useStyles();

    return <Observer>
        {() => (
            <div className={classes.container}>
                {store.payments.length > 0 &&
                    <PaymentsTable payments={store.payments} totalLabel={'Subtotal'}/>}
                <ShowPackageItemsDeliveryDetails items={store.itemsAndCategories} />
            </div>
        )}
    </Observer>
}

export default PackageDeliveryResume;
