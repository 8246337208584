import React, {useEffect} from "react";
import {useStyles} from "./CustomCategory.style"
import {useCommonContext} from "../../contexts/CommonContext";
import {useI18nContext} from "../../contexts/I18nContext";
import CustomCategoryStore from "./CustomCategoryStore";
import useOnEnterPress from "../../hooks/EnterPress";
import {Observer} from "mobx-react-lite";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import ButtonType from "../../ui_components/Button/ButtonType";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CustomCategoryRow from "./CustomCategoryRow/CustomCategoryRow";
import NewCustomCategoryButton from "../../shared_components/Buttons/NewCustomCategoryButton/NewCustomCategoryButton";
import { useUserContext } from "../../contexts/UserContext";
import Access from "../../shared_components/Access/AllowAccess/Access";


const CustomCategory:React.FC = () => {
    const commonStore = useCommonContext()
    const i18n = useI18nContext()
    const customCategoryStore = new CustomCategoryStore(commonStore)
    const classes = useStyles()
    const userStore = useUserContext();

    useEffect(() => {
        customCategoryStore.getElements()
    })

    const onUpdate = async () => {
        await customCategoryStore.getElements()
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await customCategoryStore.goToPage(page, pageSize);
    }

    const onCustomCategorySearch = () => {
        customCategoryStore.search()
    }
    useOnEnterPress(onCustomCategorySearch,[customCategoryStore])

    return (
        <Observer>
            {() => (
                <div className={classes.custom_category_container}>
                    {userStore.hasAccess(Access.CREATE_EDIT_CUSTOM_CATEGORY) &&
                        <Box className={classes.tools_container}
                            width={Width.FULL_WIDTH}
                            justifyContent={Alignment.END}>
                            <NewCustomCategoryButton type={ButtonType.SECONDARY} onSave={onUpdate} rounded/>
                        </Box>
                    }
                    <Box className={classes.filter_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={customCategoryStore.filter}
                                     placeholder={i18n.translate("CUSTOM_CATEGORY_PLACEHOLDER")}
                                     onChange={(value)=>
                                        {customCategoryStore.setFilter(value)}}
                                     onEnter={()=>customCategoryStore.search()}/>
                        <Button
                            onClick={onCustomCategorySearch}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}/>
                    </Box>
                    {!customCategoryStore.isEmpty &&
                    <div className={classes.custom_category_table}>
                        <ShowPagination onChange={onPaginationChange}
                                        elementLabel={i18n.translate("CUSTOM_CATEGORIES")}
                                        pagination={customCategoryStore.pagination}/>
                        <div className={classes.root_elements_container}>
                            {customCategoryStore.customCategory.map((customCategory,i)=>(
                                <CustomCategoryRow
                                    onUpdate = {onUpdate}
                                    customCategory={customCategory}
                                    key={i}
                                />
                            ))}
                        </div>
                        <ShowPagination
                            pagination={customCategoryStore.pagination}
                            onChange={onPaginationChange}
                            elementLabel={i18n.translate("CUSTOM_CATEGORIES")}/>
                    </div>}
                    {customCategoryStore.isEmpty &&
                    <MessageCard
                        title={i18n.translate("NO_CUSTOM_CATEGORIES_TITLE")}
                        icon={StorefrontIcon}
                        subtitle={i18n.translate("NO_CUSTOM_CATEGORIES_SUBTITLE")}/> }
                </div>
            )}
        </Observer>
    )
}
export default CustomCategory
