import {makeAutoObservable} from "mobx";
import PackageItem from "../../../model/packageDelivery/packageItem/PackageItem";
import CommonStore from "../../../stores/CommonStore";
import InventoryItem from "../../../model/packageDelivery/inventoryItem/InventoryItem";
import {InventoryItemService} from "../../../service/packageDelivery/InventoryItem/InventoryItemService";


const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"],
}
class AddPackageItemStore {
    public suggestedItems:InventoryItem[]
    public id: string
    public name: string
    public amount: number
    public comments: string
    public oneItemPrice:number
    public oneItemFinalPrice:number
    public itemPrice: number
    public finalItemPrice: number

    public categoryId?: string

    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, categoryId?: string, packageItem?: PackageItem) {
        this.suggestedItems = [];
        this.commonStore = commonStore;
        this.categoryId = categoryId;

        if (packageItem!=null) {
            this.id = packageItem.id;
            this.name = packageItem.name;

            this.oneItemPrice = packageItem.itemPrice;
            this.oneItemFinalPrice = packageItem.finalItemPrice;
            this.itemPrice = 0;
            this.finalItemPrice = 0;

            this.amount = packageItem.amount;
            this.getItemPrice();
            this.comments = packageItem.comments;
        } else {
            this.id = "";
            this.name = "";
            this.amount = 1;
            this.comments = "";

            this.oneItemPrice = 0;
            this.oneItemFinalPrice = 0;

            this.itemPrice = 0;
            this.finalItemPrice = 0;
        }
        makeAutoObservable(this);
    }

    public onPackageItemSave(): PackageItem{
        return new PackageItem(
            this.id,
            this.name,
            this.amount,
            this.comments,

            this.itemPrice,
            this.finalItemPrice
        );
    }

    public resetItem(){
        this.id = ""
        this.name = ""
        this.amount = 1
        this.comments = ""

        this.oneItemPrice = 0
        this.oneItemFinalPrice = 0
        this.getItemPrice()
    }

    private getItemPrice(){
        this.itemPrice = parseFloat(parseFloat((this.oneItemPrice*this.amount).toFixed(3)).toFixed(2))
        this.finalItemPrice = parseFloat(parseFloat((this.oneItemFinalPrice*this.amount).toFixed(3)).toFixed(2)) 
    }


    public async setName(newName:string){
        this.id = ""
        this.name = newName
        this.getInventoryItems(newName)
    }
    public setAmount(newAmount:number){
        this.amount = newAmount
        this.getItemPrice()
    }

    private async getInventoryItems(name:string){
        const response = await InventoryItemService.get(1,10,name)
        this.commonStore.processErrors(response)
        this.processError(response.error)

        if(response.success){
            this.suggestedItems = response.data?.data || []
        }else {
            this.suggestedItems = []
        }
    }

    processError(error?: string) {
        if (error) {
            if (ERROR_MAP.unauthorized.includes(error)) {
                this.commonStore.showErrorToast(error); //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
            }
        }
    }
    public setSelectedItem(itemId?:string){
        const selectedItem = this.suggestedItems.find(e=> e.id === itemId);
        if (selectedItem != null) {
            this.id = selectedItem.id;
            this.name = selectedItem.name;
            this.comments = selectedItem.comments;
            this.oneItemPrice = selectedItem.price;
            this.oneItemFinalPrice = selectedItem.price;
            this.getItemPrice();
        }
    }

    //ItemPrice
    public setFinalItemPrice(newFinalItemPrice: number) {
        this.finalItemPrice = newFinalItemPrice;
    }
}

export default AddPackageItemStore;