import Geo from "../../entities/Geo";
import Phone from "../../entities/Phone";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";
import DeliveryPersonResponse from "./DeliveryPersonInfoResponse";
import ItemsAndCategories from "./ItemsAndCategories";
import { PackageDeliveryType } from "./PackageDeliveryType";

class PackageDeliveryOrderResponse {
  public id: string;
  public shippingId: string;
  public orderNumber: string;
  public agencyId: string;
  public agencyName: string;
  public clientId: string;
  public clientName: string;
  public clientPhone: Phone | null;
  public contactId: string;
  public contactName: string;
  public contactPhone: Phone | null;
  public userId: string;
  public userName: string;
  public deliveryPersonsInfo: DeliveryPersonResponse[];
  public currentDeliveryPersonInfo?: DeliveryPersonResponse
  public totalToPay: number;
  public createdAt: number;
  public status: ProductOrderStatus;
  public address: string;
  public geo: Geo;
  public itemsAndCategories: ItemsAndCategories[];
  public totalWeight: number;
  public payed?: boolean;
  public observations: string;
  public deliveryType: PackageDeliveryType;

  constructor(
    id: string,
    shippingId: string,
    orderNumber: string,
    agencyId: string,
    agencyName: string,
    clientId: string,
    clientName: string,
    clientPhone: Phone | null,
    contactId: string,
    contactName: string,
    contactPhone: Phone | null,
    userId: string,
    userName: string,
    deliveryPersonsInfo: DeliveryPersonResponse[],
    currentDeliveryPersonInfo: DeliveryPersonResponse,
    totalToPay: number,
    status: ProductOrderStatus,
    createdAt: number,
    address: string,
    geo: Geo,
    itemsAndCategories: ItemsAndCategories[],
    totalWeight: number,
    payed: boolean,
    observations: string,
    deliveryType: PackageDeliveryType,
  ) {
    this.id = id;
    this.shippingId = shippingId;
    this.orderNumber = orderNumber;
    this.agencyId = agencyId;
    this.agencyName = agencyName;
    this.clientId = clientId;
    this.clientName = clientName;
    this.clientPhone = clientPhone;
    this.contactId = contactId;
    this.contactName = contactName;
    this.contactPhone = contactPhone;
    this.userId = userId;
    this.userName = userName;
    this.contactName = contactName;
    this.deliveryPersonsInfo = deliveryPersonsInfo;
    this.currentDeliveryPersonInfo = currentDeliveryPersonInfo;
    this.totalToPay = totalToPay;
    this.status = status;
    this.createdAt = createdAt;
    this.address = address;
    this.geo = geo;
    this.itemsAndCategories = itemsAndCategories;
    this.totalWeight = totalWeight;
    this.payed = payed;
    this.observations = observations;
    this.deliveryType = deliveryType;
  }
}

export default PackageDeliveryOrderResponse;
