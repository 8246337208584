import React, {useEffect} from 'react';
import {useStyles} from "./Modal.style";
import ModalProperties from "./ModalProperties";
import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from "@material-ui/core";
import PageLoading from "../PageLoading/PageLoading";
import {Observer} from "mobx-react-lite";
import clsx from "clsx";
import {useCommonContext} from "../../contexts/CommonContext";

const Modal: React.FC<ModalProperties> = ({
                                              className = '',
                                              onClose,
                                              onPressEnter = () => {},
                                              title,
                                              subtitle,
                                              open,
                                              loading = false,
                                              children,
                                              fullScreen = true
                                          }) => {
    const classes = useStyles();
    const commonStore = useCommonContext();
    const onModalClose = () => {
        commonStore.emitEvent('dialog_closed');
        onClose();
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            if (onPressEnter) {
                onPressEnter();
            }
        }
    };

    useEffect(() => {
        if (open) {
            window.addEventListener('keydown', handleKeyPress);
        } else {
            window.removeEventListener('keydown', handleKeyPress);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    useEffect(() => {
        if (!open) {
            commonStore.emitEvent('dialog_closed');
        }
    }, [open, commonStore])

    return (
        <Dialog maxWidth={false} fullScreen={fullScreen} onClose={onModalClose} open={open}>
            <div className={classes.header_container}>
                <div className={classes.title}>
                    {title}
                    <span>{subtitle}</span>
                </div>
                <div className={classes.close_button} onClick={onModalClose}>
                    <CloseIcon/>
                </div>
            </div>
            <div className={clsx(classes.content_container, className)}>
                {children}
                {loading && <Observer>{() => (<PageLoading isLoading={loading}/>)}</Observer>}
            </div>
        </Dialog>
    )
}
export default Modal;
