import { PackageDeliveryType } from "./PackageDeliveryType";
import CustomCategoryWeightRange from "./customCategory/CustomCategoryWeightRange";

class WeightInfo {
    public weight: number;
    public range: CustomCategoryWeightRange;
    public subWeightPrice: number;
    public finalWeightPrice: number;

    constructor(weight?: number, range?: CustomCategoryWeightRange, subWeightPrice?: number, finalWeightPrice?: number) {
        this.weight = (weight == null) ? 0 : weight;
        this.range = (range == null) ? new CustomCategoryWeightRange("", 0, 0, 0, PackageDeliveryType.PLANE) : range;
        this.subWeightPrice = (subWeightPrice == null) ? 0 : subWeightPrice;
        this.finalWeightPrice = (finalWeightPrice == null) ? 0 : finalWeightPrice;
    }
}
export default WeightInfo;