import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        backgroundColor: theme.palette.grey[300],
        flexGrow: 1,
        height: 'calc( 100vh - 250px)'
    },
    input: {
        width: '30%'
    },
    delivery_type_select_container: {
        width: 'calc(100% - 30px)',
        backgroundColor: '#fff',
        height: '50px',
        padding: '14px',
        display: 'flex',
        justifyContent: 'center'
    },
    values_container: {
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 122px)',
        padding: '20px',
        backgroundColor: theme.palette.common.white,
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    category_name: {
        fontWeight: 700,
        marginBottom: '5px',
    },
    range_info_container: {
        padding: '0 20px'
    },
    range_item_container: {
        display: 'flex',
        gap: '15px',
        marginBottom: '30px',
    },
    range_item: {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: '6px',
    },
    empty_delivery_type: {
        padding: '0px !important',
        marginTop: '0px !important',
        "& > div": {
            fontSize: '15px',
            fontStyle: 'italic',
        }
    }
}));

export {useStyles}