import React from "react";
import ShowText from "../../Info/ShowText/ShowText";
import {useStyles} from "../IndividualItemPriceSection/IndividualItemPriceSection.style";
import PriceInputShort from "../../Inputs/PriceInputShort/PricetInputShort";
import { CurrencyModule } from "../../../util/Currency";
import FinalCategoryPriceSectionProperties from "./FinalCategoryPriceSectionProperties";

const FinalCategoryPriceSection:React.FC<FinalCategoryPriceSectionProperties> =({
                                                                                              categoryId,
                                                                                              subFinalPrice,
                                                                                              finalPrice,
                                                                                              onFinalPriceChange,
                                                                                              disable,
                                                                                              fullWidth=false,
                                                                                              className='',
                                                                                          })=>{

    const classes = useStyles()

    //FinalPrice
    const onChangeFinalPrice = (finalPrice?: number) =>{
        onFinalPriceChange(finalPrice, categoryId);
    }

    return (
        <div className={classes.final_category_price_container}>
                <ShowText
                    title={"Sugerencia"}
                    text={CurrencyModule.format(subFinalPrice||0)}/>
                <PriceInputShort
                    label="Precios final"
                    value={finalPrice}
                    onChange={onChangeFinalPrice}/>
            </div>
    )
}
export default FinalCategoryPriceSection