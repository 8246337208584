import React, {useEffect} from 'react';
import ItemInventoryProperties from "./ItemInventoryProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ItemInventory.style";
import {Paper} from '@material-ui/core';
import PackageItem from "../../../../model/packageDelivery/packageItem/PackageItem";
import ShowText from "../../../Info/ShowText/ShowText";
import AddPackageItem from "../../../Content/AddPackageItem/AddPackageItem";
import DynamicTabs from "../../../Tabs/DynamicTabs/DynamicTabs";
import { CurrencyModule } from '../../../../util/Currency';
import ReferenceItemPriceSelect from '../../../Selects/ReferenceItemPriceSelect/ReferenceItemPriceSelect';
import useAlert from '../../../../hooks/AlertDialog';


const ItemInventory: React.FC<ItemInventoryProperties> = ({
                                                              store,
                                                          }) => {
    const classes = useStyles();
    const alert = useAlert();

    useEffect(() => { store.searchCustomCategories() });


    //Manage Category Selector
    const onCustomCategorySelectChange = async (categoryId?: string) => { await store.setSelectedCustomCategory(categoryId) };

    //Manage Categories with Items
    const onCategoryWeightChange = (newWeight?: number, categoryId?: string) => { store.setNewCategoryWeight(newWeight,categoryId) };
    const onCategoryFinalPriceChange = (finalPrice?: number, categoryId?: string)=>{ store.updateCategoryFinalPrice(finalPrice,categoryId) };
    const onFinalWeightPriceChange = (finalPrice?: number, categoryId?:string)=>{ store.updateFinalWeightPrice(finalPrice,categoryId) };
    const onFinalIndividualPriceChange = (finalIndividualPrice?: number, categoryId?: string)=>{ store.updateCategoryFinalIndividualPrice(finalIndividualPrice,categoryId) };
    const onRemovePackageItem = (packageItem?: PackageItem, categoryId?: string)=>{ store.removePackageItem(packageItem,categoryId) };
    const onEditPackageItem = (packageItem?: PackageItem, categoryId?: string)=>{ store.editPackageItem(packageItem,categoryId) };

    const onPackageItemSave = async (packageItem: PackageItem) => {
        const result = await store.save(packageItem);
        if (!result) {
            await alert('AVISO', 'El item ya existe en la lista.');
        }
    };
    const onCancel = async () => { store.cancelItemTemp() };

    return <Observer>
        {() => (
            <div className={classes.container}>
                <Paper elevation={10} className={classes.form_container}>
                    <DynamicTabs
                        customCategories={
                            store.customCategories
                                .filter(category =>
                                    category.weightRanges.some(range => store.toEnumValue(range.deliveryType.toString()) === store.deliveryType)
                                )
                        }
                        selectedCustomCategory={store.selectedCustomCategory}
                        onCustomCategorySelectChange={onCustomCategorySelectChange}
                        itemsAndCategories={store.itemsAndCategories}
                        onRemovePackageItem={onRemovePackageItem}
                        onEditPackageItem={onEditPackageItem}
                        onFinalIndividualPriceChange={onFinalIndividualPriceChange}
                        onWeightChange={onCategoryWeightChange}
                        onFinalWeightPriceChange={onFinalWeightPriceChange}
                        onFinalPriceChange={onCategoryFinalPriceChange}/>
                    <div className={classes.resume_and_new_item_container}>
                        <div className={classes.price_resume_container}>
                            {store.itemsAndCategories.map((categoryWithItem,i)=>(
                                <ShowText title={categoryWithItem.categoryInfo.name} text={CurrencyModule.format(categoryWithItem.finalPrice || 0)}/>
                            ))}
                            <ShowText className={classes.final_price} title={"Total"} text={CurrencyModule.format(store.finalPackagePrice || 0)}/>
                        </div>
                        <AddPackageItem 
                            packageItem={store.tempPackageItem}
                            onPackageItemSave={onPackageItemSave}
                            onCancel={onCancel}
                            selectedCustomCategory={store.selectedCustomCategory}/>
                    </div>
                </Paper>
                <Paper elevation={10} className={classes.form_container2}>
                    <ReferenceItemPriceSelect />
                </Paper>
                
            </div>
        )}
    </Observer>
}

export default ItemInventory;
