import React from "react";
import { useI18nContext } from "../../../contexts/I18nContext";
import { CurrencyModule } from "../../../util/Currency";
import Table from "../../../ui_components/Table/Table";
import TableType from "../../../ui_components/Table/TableType";
import ShowPackageItemsDeliveryDetailsProperties from "./ShowPackageItemsDeliveryDetailsProperties";
import { useStyles } from "./ShowPackageItemsDeliveryDetails.style";

const ShowPackageItemsDeliveryDetails: React.FC<ShowPackageItemsDeliveryDetailsProperties> = ({
    items,
}) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const tableHeader = ['Categoria / Item', 'Peso Total', 'Valor Total'];

    const tableBody = items.flatMap(category => {
        const categoryName = category.categoryInfo.name;
        const categoryWeight = category.weightInfo?.weight ?? 0;
        const categoryTotal = category.finalPrice;

        const categoryRow = [
            `${categoryName} (${category.packageItem.length})`,
            `${categoryWeight} lbs`,
            CurrencyModule.format(categoryTotal ?? 0, 'USD')
        ];

        const itemRows = category.packageItem.map(item => [
            <span className={classes.item_row}>{item.name}</span>,
            '',
            ''
        ]);

        return [categoryRow, ...itemRows];
    });

    const grandTotal = items.reduce((total, category) => 
        total + (category.finalPrice ?? 0)
    , 0);
    tableBody.push([
        i18n.translate("TOTAL"),
        '',
        CurrencyModule.format(grandTotal, 'USD')
    ]);

    return (
        <div className={classes.container}>
            <div className={classes.info_container}>
                <Table title={'Items'} tableType={TableType.SECONDARY} body={tableBody} header={tableHeader} />
            </div>
        </div>
    );
};

export default ShowPackageItemsDeliveryDetails;
