import CustomCategoryWeightRange from "./CustomCategoryWeightRange";
import CustomCategoryType from "../../../entities/CustomCategoryType";

class CustomCategory {
    public id: string ;
    public name: string;
    public description: string;
    public createdAt: number;
    public weightRanges: CustomCategoryWeightRange[];
    public categoryType: CustomCategoryType;

    constructor(
        id: string,
        name: string,
        description: string,
        createdAt: number,
        weightRanges: CustomCategoryWeightRange[],
        categoryType: CustomCategoryType
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.createdAt = createdAt;
        this.weightRanges = weightRanges;
        this.categoryType = categoryType;
    }
}

export default CustomCategory;