import OrderAdditionalPrice from "../../entities/OrderAdditionalPriceRequest";
import AddPaymentRequest from "../payment/AddPaymentRequest";
import ItemsAndCategories from "./ItemsAndCategories";
import { PackageDeliveryType } from "./PackageDeliveryType";

class PackageDeliveryOrderRequest {
    public id?: string;
    public orderNumber?: string;
    public clientId: string;
    public contactId: string;
    public payments:AddPaymentRequest[];
    public itemsAndCategories:ItemsAndCategories[];
    public additionalPrices: OrderAdditionalPrice[];
    public agencyId?: string;
    public userId?: string;
    public totalToPay?: number;
    public serviceCost?: number;
    public paymentFee?: number;
    public observations?: string;
    public deliveryType: PackageDeliveryType;

    constructor(
        clientId: string,
        contactId: string,
        payments:AddPaymentRequest[],
        itemsAndCategories:ItemsAndCategories[],
        additionalPrices: OrderAdditionalPrice[],
        deliveryType: PackageDeliveryType,
        id?: string,
        orderNumber?: string,
        agencyId?: string,
        userId?: string,
        totalToPay?: number,
        serviceCost?: number,
        paymentFee?: number,
        observations?: string
    ) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.clientId = clientId;
        this.contactId = contactId;
        this.agencyId = agencyId;
        this.userId = userId;
        this.totalToPay = totalToPay;
        this.payments = payments;
        this.itemsAndCategories = itemsAndCategories;
        this.additionalPrices = additionalPrices;
        this.serviceCost = serviceCost;
        this.paymentFee = paymentFee;
        this.observations = observations;
        this.deliveryType = deliveryType;
    }
}
export default PackageDeliveryOrderRequest;
