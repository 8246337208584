
class PackageItem {

    public id: string;
    public name: string;
    public amount: number;
    public comments: string;

    public itemPrice: number;
    public finalItemPrice: number;

    constructor(id: string, name: string, amount: number, comments: string, itemPrice: number, finalItemPrice: number) {
        this.id = id;
        this.name = name;
        this.amount = amount;
        this.comments = comments;

        this.itemPrice = itemPrice;
        this.finalItemPrice = finalItemPrice;
    }
}
export default PackageItem