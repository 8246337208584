import React from 'react';
import NewMoneyExchangeRateButtonProperties from "./NewMoneyExchangeRateButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewMoneyExchangeRateStore from "./NewMoneyExchangeRateStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./NewMoneyExchangeRateButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import PriceInput from '../../Inputs/PriceInput/PricetInput';
import CurrencySelect from '../../Selects/CurrencySelect/CurrencySelect';
import Currency from '../../../entities/Currency';
import ShowText from '../../Info/ShowText/ShowText';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";


const NewMoneyExchangeRateButton: React.FC<NewMoneyExchangeRateButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  moneyExchangeId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewMoneyExchangeRateStore(commonContext, moneyExchangeId);

    const userStore = useUserContext();
    if (!userStore.hasAccess(Access.CREATE_MONEY_DELIVERY_EXCHANGE_RATE.valueOf())) {
        return <></>;
    }

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onValueChange = (newValue: number) => store.moneyExchange.value = newValue;
    const onCurrencyChange = (newCurrency: Currency) => store.moneyExchange.currency = newCurrency;

    const onMoneyExchangeRateSave = async () => {
        if (!store.enableButton) {
            return;
        }
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const text = i18n.translate((store.isEditing ? "EDIT_MONEY_EXCHANGES" : "NEW_MONEY_EXCHANGES"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text}
                    onClose={onCloseModal}
                    onPressEnter={onMoneyExchangeRateSave}
                    open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                            <ShowText text={i18n.translate("MONEY_EXCHANGE_CREATION_EXPLANATION")} className={classes.full_width}/>
                            <PriceInput error={i18n.translate(store.changeError)}
                                fullWidth autoFocus className={classes.input}
                                value={store.moneyExchange?.value}
                                onChange={onValueChange}/>
                            <CurrencySelect fullWidth className={classes.input}
                                error={i18n.translate(store.currencyError)}
                                selected={store.currency}
                                onChange={onCurrencyChange}/>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onMoneyExchangeRateSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewMoneyExchangeRateButton;
