import React from 'react';
import ShowPackageDeliveryTypeProperties from "./ShowPackageDeliveryTypeProperties";
import {useStyles} from "./ShowPackageDeliveryType.style";
import clsx from "clsx";
import { PackageDeliveryType } from '../../../model/packageDelivery/PackageDeliveryType';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

const ShowPackageDeliveryType: React.FC<ShowPackageDeliveryTypeProperties> = ({
    deliveryType,
    className = '',
}) => {

    const classes = useStyles();

    const getDeliveryTypeIcon = () => {
        switch (deliveryType) {
            case PackageDeliveryType[PackageDeliveryType.PLANE]:
                return <AirplanemodeActiveIcon></AirplanemodeActiveIcon>;
            case PackageDeliveryType[PackageDeliveryType.SHIP]:
                return <DirectionsBoatIcon></DirectionsBoatIcon>;
            case PackageDeliveryType[PackageDeliveryType.EXPRESS]:
                return <AccessAlarmsIcon></AccessAlarmsIcon>;
            case PackageDeliveryType[PackageDeliveryType.SEMI_EXPRESS]:
                return <AddAlarmIcon></AddAlarmIcon>;
            default:
                return <></>;
        }
    }

    const getDeliveryTypeText = () => {
        switch (deliveryType) {
            case PackageDeliveryType[PackageDeliveryType.PLANE]:
                return 'Avión';
            case PackageDeliveryType[PackageDeliveryType.SHIP]:
                return 'Barco';
            case PackageDeliveryType[PackageDeliveryType.EXPRESS]:
                return 'Express';
            case PackageDeliveryType[PackageDeliveryType.SEMI_EXPRESS]:
                return 'Semi Express';
            default:
                return <></>
        }
    }

    return (
        <div className={clsx(classes.container, className)}>
            <div>{getDeliveryTypeIcon()}</div>
            <div>{getDeliveryTypeText()}</div>
        </div>
    )
}


export default ShowPackageDeliveryType;