import Currency from "../../entities/Currency";
import DeliveryOrderComment from "../../entities/productOrder/DeliveryOrderComment";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";
import PackageDeliveryOrderContactResponse from "./PackageDeliveryOrderContactResponse";
import ItemsAndCategories from "./ItemsAndCategories";
import Agency from "../../entities/Agency";
import OrderPaymentResponse from "../payment/OrderPaymentResponse";
import { PackageDeliveryType } from "./PackageDeliveryType";

class PackageDeliveryOrderWithDetailsResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public agency : Agency;
    public clientId: string;
    public clientName: string;
    public contact: PackageDeliveryOrderContactResponse;
    public totalToPay: number;
    public createdAt: number;
    public amountToReceive: number;
    public currencyToReceive: Currency;
    public currentStatus: ProductOrderStatus;
    public comments: DeliveryOrderComment[];
    public difficultDelivery: boolean;
    public itemsAndCategories : ItemsAndCategories[]
    public payments: OrderPaymentResponse[];
    public deliveryType: PackageDeliveryType;

    constructor(
        id: string,
        orderNumber: string,
        agencyId: string,
        agency: Agency,
        clientId: string,
        clientName: string,
        contact: PackageDeliveryOrderContactResponse,
        totalToPay: number,
        createdAt: number,
        amountToReceive: number,
        currencyToReceive: Currency,
        currentStatus: ProductOrderStatus,
        comments: DeliveryOrderComment[],
        difficultDelivery: boolean,
        itemsAndCategories: ItemsAndCategories[],
        payments: OrderPaymentResponse[],
        deliveryType: PackageDeliveryType
    ) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.agency = agency;
        this.clientId = clientId;
        this.clientName = clientName;
        this.contact = contact;
        this.totalToPay = totalToPay;
        this.createdAt = createdAt;
        this.amountToReceive = amountToReceive;
        this.currencyToReceive = currencyToReceive;
        this.currentStatus = currentStatus;
        this.comments = comments;
        this.difficultDelivery = difficultDelivery;
        this.itemsAndCategories = itemsAndCategories;
        this.payments = payments;
        this.deliveryType = deliveryType;
    }

}

export default PackageDeliveryOrderWithDetailsResponse;