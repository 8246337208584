import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import CustomCategory from "../../../model/packageDelivery/customCategory/CustomCategory";
import { CustomCategoryService } from "../../../service/packageDelivery/customCategory/CustomCategoryService";

class CustomCategoryWeightRangesStore {

    public customCategoryId: string;
    public customCategory: CustomCategory;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;

    constructor(
        commonStore: CommonStore,
        customCategoryId: string
    ) {
        this.customCategoryId = customCategoryId;
        this.isModalOpen = false;
        this.customCategory = {} as CustomCategory;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.customCategory?.name);
    }

    @action
    public async loadCustomCategory() {
        const response = await CustomCategoryService.find(this.customCategoryId!)
        if (response.success && response.data) {
            this.customCategory = response.data;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        this.loading = true;
        await this.loadCustomCategory();
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.customCategory == null || this.customCategory.weightRanges == null || this.customCategory.weightRanges.length === 0;
    }

}

export default CustomCategoryWeightRangesStore;