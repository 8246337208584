import CustomCategoryWeightRange from "../../../model/packageDelivery/customCategory/CustomCategoryWeightRange";
import {Config} from "../../../util/Config";
import {RestClient} from "../../../util/RestClient";
import Response from "../../../model/Response";
import Request from "../../../model/Request";
import { PackageDeliveryType } from "../../../model/packageDelivery/PackageDeliveryType";


export module CustomCategoryWeightRangeService{

    export async function find(weightRangeId:string, customCategoryId:string):Promise<Response<CustomCategoryWeightRange>>{
        const url = Config.get("FIND_CUSTOM_CATEGORY_WEIGHT_RANGE_URL",{customCategoryId: customCategoryId,weightRangeId:weightRangeId})
        const request = new Request(url)
        const response = await RestClient.get(request)
        if(response.success){
            const data = response.data
            if(data){
                const weightRange = new CustomCategoryWeightRange(
                    data.id,
                    data.from,
                    data.to,
                    data.price,
                    data.deliveryType
                )
                return new Response<CustomCategoryWeightRange>(true,weightRange,response.error)
            }
        }
        return new Response<CustomCategoryWeightRange>(true,undefined,response.error,response.status)
    }

    export async function getPriceFromWeight(weight: number, customCategoryId: string, deliveryType: PackageDeliveryType): Promise<Response<CustomCategoryWeightRange>>{
        const url = Config.get("GET_PRICE_FROM_CUSTOM_CATEGORY_URL", {
            customCategoryId,
            weight,
            deliveryType: PackageDeliveryType[deliveryType]
        });
        const request = new Request(url);
        const response = await RestClient.get(request);
        if (response.success) {
            const data = response.data;
            if (data) {
                const weightRange = new CustomCategoryWeightRange(
                    data.id,
                    data.from,
                    data.to,
                    data.price,
                    data.deliveryType
                );
                return new Response<CustomCategoryWeightRange>(true, weightRange, response.error);
            }
        }
        return new Response<CustomCategoryWeightRange>(false, undefined, response.error, response.status);
    }

    export async function create(data:CustomCategoryWeightRange, customCategoryId:string): Promise<Response<any>>{
        const url = Config.get("CREATE_CUSTOM_CATEGORY_WEIGHT_RANGE_URL",{customCategoryId})
        const request = new Request(url,data)
        return await RestClient.post(request)
    }

    export async function update(data:CustomCategoryWeightRange, customCategoryId:string): Promise<Response<any>>{
        const url = Config.get("UPDATE_CUSTOM_CATEGORY_WEIGHT_RANGE_URL",{customCategoryId: customCategoryId,weightRangeId:data.id})
        const request = new Request(url,data)
        return await RestClient.put(request)
    }

    export async function remove(weightRangeId:string, customCategoryId:string): Promise<Response<any>>{
        const url = Config.get("REMOVE_CUSTOM_CATEGORY_WEIGHT_RANGE_URL",{customCategoryId: customCategoryId,weightRangeId:weightRangeId})
        const request = new Request(url)
        return await RestClient.remove(request)
    }
}