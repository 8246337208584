import React from 'react';
import AddPackageItemProperties from "./AddPackageItemProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import AddPackageItemStore from "./AddPackageItemStore";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./AddPackageItem.style";
import TextInputShort from "../../../ui_components/TextInputShort/TextInputShort";
import TextInputShortType from "../../../ui_components/TextInputShort/TextInputShortType";
import IndividualItemPriceSection from "../../Selects/IndividualItemPriceSection/IndividualItemPriceSection";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import SuggestedItemSelect from "../../Selects/SuggestedItemSelect/SuggestedItemSelect";


const AddPackageItem: React.FC<AddPackageItemProperties> = ({
                                                                selectedCustomCategory,
                                                                packageItem,
                                                                onPackageItemSave,
                                                                onCancel,
                                                                disabled,
                                                                className = '',
                                                            }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const store = new AddPackageItemStore(commonStore,selectedCustomCategory?.id, packageItem);

    //Amount, Name and Description
    const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { store.setAmount(+event.target.value) }
    const onNameChange = (newName:string)=> { store.setName(newName.toUpperCase()) }

    //ItemPrice
    const onFinalItemPriceChange = (newFinalItemPrice:number) => { store.setFinalItemPrice(newFinalItemPrice) }

    //Save and cancel Buttons
    const onSavePackageItem = () => { onPackageItemSave(store.onPackageItemSave()) };

    const onCancelx = () => { 
        store.resetItem();
        onCancel();
    }

    //MangeSavedItems
    const onInventoryItemChange = (itemId?: string) => {
        store.setSelectedItem(itemId);
    }

    return <Observer>
        {() => (
            <div className={classes.container}>
                <div className={classes.amount_and_name_container}>
                    <TextInputShort
                        className={classes.input_amount}
                        type={TextInputShortType.NUMBER}
                        label={i18n.translate("AMOUNT")}
                        value={store.amount}
                        onChange={onChangeAmount}/>
                    <SuggestedItemSelect fullWidth
                        className={classes.input_name}
                        required
                        itemName={store.name}
                        onItemNameChange={onNameChange}
                        inventoryItems={store.suggestedItems}
                        onInventoryItemChange={onInventoryItemChange}/>
                </div>
                <IndividualItemPriceSection
                        itemPrice={store.itemPrice}
                        finalItemPrice={store.finalItemPrice}
                        onFinalItemPriceChange={onFinalItemPriceChange}/>
                <div className={classes.btn_container}>
                    <Button
                        disabled={!(store.name !== null && store.name !== "" && store.amount != null && selectedCustomCategory != null) }
                        //loading={store.loading}
                        className={classes.save_btn}
                        onClick={onSavePackageItem}
                        type={ButtonType.SECONDARY}
                        text={i18n.translate("ADD_TO_LIST")}/>
                    <Button
                        //loading={store.loading}
                        className={classes.cancel_btn}
                        onClick={onCancelx}
                        type={ButtonType.DEFAULT}
                        text={i18n.translate("CANCEL")}/>
                </div>
            </div>
        )}
    </Observer>
}
export default AddPackageItem;
