import { PackageDeliveryType } from "../PackageDeliveryType";

class CustomCategoryWeightRange {
    public id: string;
    public from: number;
    public to: number;
    public price: number;
    public deliveryType: PackageDeliveryType;

    constructor(
        id: string,
        from: number,
        to: number, 
        price: number,
        deliveryType: PackageDeliveryType,
    ) {
        this.id = id;
        this.from = from;
        this.to = to;
        this.price = price;
        this.deliveryType = deliveryType;
    }
}
export default CustomCategoryWeightRange