import React, {useEffect, useRef} from 'react';
import InvoiceToPrintProperties from "./InvoiceToPrintProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import InvoiceToPrintStore from "./InvoiceToPrintStore";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./InvoiceToPrint.style";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import {CurrencyModule, getCurrencyText} from '../../../util/Currency';
import clsx from 'clsx';
import ShowOrderNumber from '../../Info/ShowOrderNumber/ShowOrderNumber';
import ShowDate from '../../Info/ShowDate/ShowDate';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import ItemsAndCategories from "../../../model/packageDelivery/ItemsAndCategories";
import CustomCategoryType from "../../../entities/CustomCategoryType";
import PrintButton from "../../Buttons/PrintButton/PrintButton";
import Print from "./Print";
import ArticleDeliveryOrderArticleResponse from '../../../model/articleDelivery/ArticleDeliveryOrderArticleResponse';
import DeliveryOrderType from "../../../model/DeliveryOrderType";
import OrderToPrint from "../../../model/orderToPrint/OrderToPrint";
import ShopOrderProductResponse from '../../../model/shop/ShopOrderProductResponse';
import Currency from '../../../entities/Currency';

const InvoiceToPrint: React.FC<InvoiceToPrintProperties> = ({

                                                                showText = true,
                                                                productOrderIds,
                                                                isInvoice,
                                                                  }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new InvoiceToPrintStore(commonContext, productOrderIds);
    const testRef = useRef<Print | null>(null);


    useEffect(() => {
        //store.loadAgencyData();
        store.loadProductOrderToPrintInvoice();
    })

    function formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());

        return `${day}/${month}/${year}`;
    }

    const formatPrice = (price: number | bigint) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(price);
    };
    const text = isInvoice ? "Factura" : "Orden de envío";

    const InvoiceData3: React.FC<{ itemsAndCategories : ItemsAndCategories}> = ({itemsAndCategories}) => {
        const items = [];
        for (const item of itemsAndCategories.packageItem) {
            items.push({
                quantity: item.amount,
                name: item.name,
                price: item.finalItemPrice,
            });
        }

        return (
            // <Table size='small'>
            <Table>
                <TableBody>
                    {items.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell className={classes.columnOne} style={{padding: '0px', borderBottom: 'none',}}>{row.quantity}</TableCell>
                            <TableCell className={classes.columnTwo} style={{padding: '0px', borderBottom: 'none',}}>{row.name}</TableCell>
                            <TableCell className={classes.columnThree} style={{padding: '0px', borderBottom: 'none',}}>{itemsAndCategories.categoryInfo.categoryType === CustomCategoryType.INDIVIDUAL ? (isInvoice && formatPrice(row.price)) : ''}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };
    function renderOrderDetails(order :OrderToPrint, index : number) {
        return (
            <div>
                {order.orderType === DeliveryOrderType.MONEY_DELIVERY && renderMoneyDeliveryDetails(order)}
                {order.orderType === DeliveryOrderType.ARTICLE_DELIVERY && renderArticleDeliveryDetails(order)}
                {order.orderType === DeliveryOrderType.PACKAGE_DELIVERY && renderPackageDeliveryDetails(order, index)}
                {order.orderType === DeliveryOrderType.SHOP_ORDER && renderShopOrderDetails(order)}
            </div>
        );
    }

    function renderShopOrderDetails(shopOrder: OrderToPrint) {
        return (
            <>
                <div className="invoice">
                    <div className={classes.top_header}>
                        <div className="header">
                            <div className={classes.name}><b style={{ textTransform: 'uppercase', fontSize: '20px' }}>{shopOrder.agencyName}</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase' }}>{shopOrder.agencyPhone}</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>{shopOrder.agencyAddress}</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase' }}>{shopOrder.agencyEmail}</b></div>

                        </div>
                        <div className={classes.header_text}>
                            <h3>{text}</h3>
                        </div>
                        <div className={classes.resume} id="project">
                            <div className={classes.resume_container}>
                                <div className={classes.columnName}>
                                    {shopOrder.shippingId !== null && shopOrder.shippingId !== ""  && (
                                        <div className={classes.name}><b>{"No Envio:"}</b></div>
                                    )}
                                    {shopOrder.orderNumber !== null && (
                                        <div className={classes.name}><b>{"No Orden:"}</b></div>
                                    )}
                                    {shopOrder.createdAt !== null && (
                                        <div className={classes.name}><b>{"Fecha:"}</b></div>
                                    )}
                                    {shopOrder.totalToPay !== null && shopOrder.totalToPay !== 0 && isInvoice &&(
                                        <div className={classes.name}><b>{"Importe Total:"}</b></div>
                                    )}
                                </div>
                                <div className={classes.columnData}>
                                    {shopOrder.shippingId !== null && shopOrder.shippingId !== "" && (
                                        <div className={classes.data}>{shopOrder.shippingId}</div>
                                    )}
                                    {shopOrder.orderNumber !== null && (
                                        <div className={classes.data}>{shopOrder.orderNumber}</div>
                                    )}
                                    {shopOrder.createdAt !== null && (
                                        <div className={classes.data}>{formatDate(shopOrder.createdAt)}</div>
                                    )}

                                    {shopOrder.totalToPay !== null && shopOrder.totalToPay !== 0 && isInvoice && (
                                        <div className={classes.data}>{`${formatPrice(shopOrder.totalToPay)}`}</div>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className={clsx(classes.top_header,classes.margin_top_4_deliver)}>
                        <div  className={classes.resume} id="project">
                            <div className={classes.shop_resume_container}>
                                <div className={classes.columnName}>
                                    <div><b>{"Cliente"}</b></div>
                                    <div>{shopOrder.clientName}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.articles_container}>
                    {shopOrder.products.map((product: ShopOrderProductResponse) => (
                        <div>
                            <div className={classes.invoice_item}>
                                <div className={classes.product_name}>
                                {product.shopProductName}
                                </div>
                                <div className={classes.product_details}>
                                <span>{CurrencyModule.format(product.price, Currency.DOLLAR)}</span> x {product.amount}
                                </div>
                                <div className={classes.product_total}>
                                Total: {CurrencyModule.format(product.price * product.amount, Currency.DOLLAR)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                    <div className={classes.additionalText}>
                        <div>
                            <div className={classes.spacedText}>
                                {isInvoice ? (
                                    <>
                                        <div>Firma del Cliente: ___________________</div>
                                        <div className={classes.margin_left}>Firma del Empleado: ___________________</div>
                                    </>
                                ) : (
                                    <>
                                        <div>Firma del Destinatario: ___________________</div>
                                        <div className={classes.margin_left}>Firma del Repartidor: ___________________</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function renderArticleDeliveryDetails(articleDeliveryOrder :OrderToPrint) {
        return (
            <>
                <div className={classes.column_container_deliver}>
                    <div className={classes.column_deliver}>
                        <div className={classes.row_deliver}>
                            <div className={classes.subtitle_deliver}>{`${i18n.translate("CLIENT")}:`} </div>
                            &nbsp;
                            <div>{articleDeliveryOrder.clientName}</div>
                        </div>
                        <div className={classes.row_deliver}>
                            <div className={classes.width_90_deliver}>
                                <div className={classes.sign_container_deliver}/>
                                <div className={classes.row_deliver}>
                                    <div className={classes.subtitle_deliver}>{`CI`} </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.row_deliver}>
                            <div className={classes.width_90_deliver}>
                                <div className={classes.sign_container_deliver}/>
                                <div className={classes.row_deliver}>
                                    <div className={classes.subtitle_deliver}>{`Firma y Fecha`} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.column_deliver}>
                        <div className={classes.row_deliver}>
                            <div className={classes.subtitle_deliver}>{`${i18n.translate("ADDRESSEE")}:`} </div>
                        </div>
                        <div className={classes.row_deliver}>
                            {articleDeliveryOrder.contact?.name + (articleDeliveryOrder.contact?.lastName ? ' ' + articleDeliveryOrder.contact?.lastName : '')}
                        </div>
                        <div className={classes.row_deliver}>
                            {articleDeliveryOrder.contact?.mobilePhone && articleDeliveryOrder.contact?.mobilePhone.number !== "" && <span>{'Celular: ' + articleDeliveryOrder.contact?.mobilePhone.number}</span>}
                            {articleDeliveryOrder.contact?.homePhone && articleDeliveryOrder.contact?.homePhone.number !== "" && <span>{'Fijo: ' + articleDeliveryOrder.contact?.homePhone.number}</span>}
                        </div>
                        <div className={classes.row_deliver}>
                            <span><strong>Entregar en: </strong> {articleDeliveryOrder.contact?.address}. {articleDeliveryOrder.contact?.neighborhood ? articleDeliveryOrder.contact?.neighborhood + '. ' : ''} {articleDeliveryOrder.contact?.geo.name + '. ' + articleDeliveryOrder.contact.geo.parentInfo}</span>
                        </div>
                    </div>
                    <div className={classes.column_deliver}>
                        <div>
                            <ShowOrderNumber orderNumber={articleDeliveryOrder.orderNumber}/>
                        </div>
                        <div className={classes.row_deliver}>
                            <ShowDate timestamp={articleDeliveryOrder.createdAt} showTime={false} className={classes.margin_top_4_deliver}/>
                        </div>
                    </div>
                </div>
                <div className={classes.articles_container}>
                    {articleDeliveryOrder.articles.map((article: ArticleDeliveryOrderArticleResponse) => (
                        <div>
                            <div className={classes.articles_name}>{article.articleName} - Cant: {article.amount}</div>
                            {article.articleDescription.split('\n').map((line: string) => (<div>{line}</div>))}
                        </div>
                    ))}
                </div>
            </>
        );
    }

    function renderPackageDeliveryDetails(packageDeliveryOrder :OrderToPrint, index : number) {
        return (
            <>
                <div className="invoice">
                    <div className={classes.top_header}>
                        <div className="header">
                            <div className={classes.name}><b style={{ textTransform: 'uppercase', fontSize: '20px' }}>{packageDeliveryOrder.agencyName}</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase' }}>{packageDeliveryOrder.agencyPhone}</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>{packageDeliveryOrder.agencyAddress}</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase' }}>{packageDeliveryOrder.agencyEmail}</b></div>

                        </div>
                        <div className={classes.header_text}>
                            <h3>{text}</h3>
                        </div>
                        <div className={classes.resume} id="project">
                            <div className={classes.resume_container}>
                                <div className={classes.columnName}>
                                    {packageDeliveryOrder.shippingId !== null && packageDeliveryOrder.shippingId !== ""  && (
                                        <div className={classes.name}><b>{"No Envio:"}</b></div>
                                    )}
                                    {packageDeliveryOrder.orderNumber !== null && (
                                        <div className={classes.name}><b>{"No Orden:"}</b></div>
                                    )}
                                    {packageDeliveryOrder.createdAt !== null && (
                                        <div className={classes.name}><b>{"Fecha:"}</b></div>
                                    )}
                                    {packageDeliveryOrder.totalToPay !== null && packageDeliveryOrder.totalToPay !== 0 && isInvoice &&(
                                        <div className={classes.name}><b>{"Importe Total:"}</b></div>
                                    )}
                                </div>
                                <div className={classes.columnData}>
                                    {packageDeliveryOrder.shippingId !== null && packageDeliveryOrder.shippingId !== "" && (
                                        <div className={classes.data}>{packageDeliveryOrder.shippingId}</div>
                                    )}
                                    {packageDeliveryOrder.orderNumber !== null && (
                                        <div className={classes.data}>{packageDeliveryOrder.orderNumber}</div>
                                    )}
                                    {packageDeliveryOrder.createdAt !== null && (
                                        <div className={classes.data}>{formatDate(packageDeliveryOrder.createdAt)}</div>
                                    )}

                                    {packageDeliveryOrder.totalToPay !== null && packageDeliveryOrder.totalToPay !== 0 && isInvoice && (
                                        <div className={classes.data}>{`${formatPrice(packageDeliveryOrder.totalToPay)}`}</div>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className={clsx(classes.top_header,classes.margin_top_4_deliver)}>

                        <div className="header">
                            <div style={{ width: '600px' }}><b>{"Destinatario"}</b></div>
                            <div>{packageDeliveryOrder.contact?.name + (packageDeliveryOrder.contact?.lastName ? ' ' + packageDeliveryOrder.contact?.lastName : '')}</div>
                            <div>
                                <span>{packageDeliveryOrder.contact?.address}.</span>
                                {packageDeliveryOrder.contact?.neighborhood && <strong>{' ' + packageDeliveryOrder.contact?.neighborhood.toUpperCase() + '. '}</strong>}
                                <strong>{packageDeliveryOrder.contact?.geo.name.toUpperCase() + '. ' + packageDeliveryOrder.contact?.geo.parentInfo!!.toUpperCase()}</strong>
                            </div>

                            <div className={classes.phoneText}>
                                <div>
                                    <div className={classes.spacedText}>
                                        <div style={{marginRight:'10px'}}>{packageDeliveryOrder.contact?.mobilePhone && packageDeliveryOrder.contact?.mobilePhone.number !== "" && (<span><b>Celular:</b> {packageDeliveryOrder.contact?.mobilePhone.number}</span>)}</div>
                                        <div style={{marginRight:'10px'}}>{packageDeliveryOrder.contact?.homePhone && packageDeliveryOrder.contact?.homePhone.number !== "" && (<span><b>Fijo:</b> {packageDeliveryOrder.contact?.homePhone.number}</span>)}</div>
                                        <div >{packageDeliveryOrder.contact?.homePhone && packageDeliveryOrder.contact?.homePhone.number !== "" && (<span><b>Fijo:</b> {packageDeliveryOrder.contact?.homePhone.number}</span>)}</div>
                                        <div >{packageDeliveryOrder.contact?.documentId && (<span><b>CI:</b> {packageDeliveryOrder.contact?.documentId}</span>)}</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div  className={classes.resume} id="project">
                            <div className={classes.resume_container}>
                                <div className={classes.columnName}>
                                    <div><b>{"Remitente"}</b></div>
                                    <div>{packageDeliveryOrder.clientName}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {packageDeliveryOrder.itemsAndCategories.map((itemsAndCategories) => (
                        <div className={classes.margin_top_8_deliver} >
                            <div key={index}>
                                {
                                    (itemsAndCategories.categoryInfo.categoryType === CustomCategoryType.GROUP ||
                                        itemsAndCategories.categoryInfo.categoryType === CustomCategoryType.INDIVIDUAL) && (
                                        <div className={classes.name}>
                                            <b>
                                                {itemsAndCategories.categoryInfo.name} ({itemsAndCategories.packageItem.length})
                                                {itemsAndCategories.categoryInfo.categoryType === CustomCategoryType.GROUP && (
                                                    ` - ${itemsAndCategories.weightInfo?.weight} lbs`
                                                )}
                                                {isInvoice && ` - ${CurrencyModule.format(itemsAndCategories.finalPrice || 0)}`}
                                            </b>
                                        </div>
                                    )
                                }
                            </div>
                            {/*<div className={classes.horizontal_line}/>*/}
                            <InvoiceData3 itemsAndCategories={itemsAndCategories}/>
                        </div>
                    ))}
                    <div className={classes.additionalText}>
                        <div>
                            <div className={classes.spacedText}>
                                {isInvoice ? (
                                    <>
                                        <div>Firma del Cliente: ___________________</div>
                                        <div className={classes.margin_left}>Firma del Empleado: ___________________</div>
                                    </>
                                ) : (
                                    <>
                                        <div>Firma del Destinatario: ___________________</div>
                                        <div className={classes.margin_left}>Firma del Repartidor: ___________________</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <h5>{isInvoice && `${i18n.translate("WAIVER")}`}</h5>
                </div>
            </>
        );
    }
    function renderMoneyDeliveryDetails(moneyDeliveryOrder :OrderToPrint) {
        return (
            <>
                {/* Render Money Delivery specific details */}
                <div className={classes.invoice_data_container_deliver}>
                    <div className={classes.column_container_deliver}>
                        <div className={classes.column_deliver}>
                            <div className={classes.row_deliver}>
                                <div className={classes.subtitle_deliver}>{`${i18n.translate("CLIENT")}:`} </div>
                                &nbsp;
                                <div>{moneyDeliveryOrder.clientName}</div>
                            </div>
                            <div className={classes.row_deliver}>
                                <div className={classes.width_90_deliver}>
                                    <div className={classes.sign_container_deliver}/>
                                    <div className={classes.row_deliver}>
                                        <div className={classes.subtitle_deliver}>{`CI`} </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.row_deliver}>
                                <div className={classes.width_90_deliver}>
                                    <div className={classes.sign_container_deliver}/>
                                    <div className={classes.row_deliver}>
                                        <div className={classes.subtitle_deliver}>{`Firma y Fecha`} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.column_deliver}>
                            <div className={classes.row_deliver}>
                                <div className={classes.subtitle_deliver}>{`${i18n.translate("ADDRESSEE")}:`} </div>
                            </div>
                            <div className={classes.row_deliver}>
                                {moneyDeliveryOrder.contact?.name + (moneyDeliveryOrder.contact?.lastName ? ' ' + moneyDeliveryOrder.contact?.lastName : '')}
                            </div>
                            <div className={classes.row_deliver}>
                                {moneyDeliveryOrder.contact?.mobilePhone && moneyDeliveryOrder.contact?.mobilePhone.number !== "" && <span>{'Celular: ' + moneyDeliveryOrder.contact?.mobilePhone.number}</span>}
                                {moneyDeliveryOrder.contact?.homePhone && moneyDeliveryOrder.contact?.homePhone.number !== "" && <span>{'Fijo: ' + moneyDeliveryOrder.contact?.homePhone.number}</span>}
                            </div>
                            <div className={classes.row_deliver}>
                                {!moneyDeliveryOrder.isBankCard && <span><strong>Entregar en: </strong> {moneyDeliveryOrder.contact?.address}. {moneyDeliveryOrder.contact?.geo.name + '. ' + moneyDeliveryOrder.contact.geo.parentInfo}</span>}
                                {moneyDeliveryOrder.isBankCard && <span><strong>Transferir a: </strong> {moneyDeliveryOrder?.bankCardNumber + ' ' + moneyDeliveryOrder?.bankCardType}</span>}
                            </div>
                        </div>
                        <div className={classes.column_deliver}>
                            <div>
                                <ShowOrderNumber orderNumber={moneyDeliveryOrder.orderNumber}/>
                            </div>
                            <div className={classes.row_deliver}>
                                <ShowDate timestamp={moneyDeliveryOrder.createdAt} showTime={false} className={classes.margin_top_4_deliver}/>
                            </div>
                            <div className={classes.margin_top_8_deliver}>
                                <div className={classes.row_deliver}><strong>Valor: {CurrencyModule.format(moneyDeliveryOrder?.amountToReceive || 0, moneyDeliveryOrder?.currencyToReceive)}</strong></div>
                                <div className={classes.row_deliver}><strong>Moneda: {getCurrencyText(moneyDeliveryOrder?.currencyToReceive)}</strong></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return <Observer>
        {() => (
            <>
                <div className={classes.view_invoice_container_deliver}>
                    <div className={classes.toolbar_container_deliver}>
                        <PrintButton
                            type={ButtonType.DEFAULT}
                            multiPages={true}
                            onClick={() => {
                                testRef.current?.onPrint();
                            }}
                        />
                    </div>
                    <Print ref={testRef} otherStyle={`.page-break { page-break-before: always; }`}>
                        <div className={classes.invoice_container_deliver}>
                            <div>
                                <div className={classes.header_text}>
                                </div>
                                {store.deliveryOrdersToPrint.map((order, index) => (
                                    <div key={index} className={clsx(classes.body_deliver, index > 0 && classes.order_item_container_deliver, index > 0 && classes.page_break)}>
                                        {/* Render the order details */}
                                        {renderOrderDetails(order, index)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Print>
                </div>
            </>
        )}
    </Observer>
}

export default InvoiceToPrint;
