import React, { useEffect, useState } from 'react';
import {Box, CardContent, IconButton, Typography} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useStyles } from './ShowArticleDeliveryItem.style';
import ShowArticleDeliveryItemProperties from './ShowArticleDeliveryItemProperties';
import { useI18nContext } from '../../../contexts/I18nContext';
import Link from '../../../ui_components/Link/Link';
import SelectOption from '../../../ui_components/Select/SelectOption';
import Select from '../../../ui_components/Select/Select';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TextInput from '../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../ui_components/TextInput/TextInputType';
import ShowImage from '../ShowImage/ShowImage';

const ShowArticleDeliveryItem: React.FC<ShowArticleDeliveryItemProperties> = ({
                                                id,
                                                title,
                                                text,
                                                imageName,
                                                imageWidth = 80,
                                                imageHeight = 80,
                                                articlePrices,
                                                isSelectable,
                                                isSelected,
                                                allowToSelectPrice = false,
                                                selectedPrice,
                                                selectedAmount,
                                                selectedContactGeoId = null,
                                                onSelect,
                                                onDeselect,
                                                onPriceSelect,
                                                onAmountSelect
                                            }: ShowArticleDeliveryItemProperties) => {

    const classes = useStyles();
    const i18n = useI18nContext();

    const [linesToShow, setlinesToShow] = React.useState(3);
    
    const [optionPrices, setOptionPrices] = useState<SelectOption[]>([]);
    const [price, setPrice] = useState<SelectOption | undefined>(selectedPrice ?
        new SelectOption(`${selectedPrice.geo!!.name} - ${selectedPrice.price} USD`, selectedPrice.geo!!.id, '', AttachMoneyIcon) : undefined);
    const [amount, setAmount] = useState<number>(selectedAmount ?? 1);

    const textLines = text ? text.split('\n') : [];

    const Icon = isSelected ? CheckBoxIcon : CheckBoxOutlineBlankIcon;

    useEffect(() => {
        setOptionPrices(articlePrices.map(r => new SelectOption(`${r.geo!!.name} - ${r.price} USD`, r.geo!!.id, '', AttachMoneyIcon)));
    }, [articlePrices])

    useEffect(() => {
        updateSelectedPrice(selectedContactGeoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContactGeoId, optionPrices])

    const updateSelectedPrice = (id?: string | null) => {
        if (allowToSelectPrice && optionPrices && optionPrices.length > 0) {
            const selectedOptionByGeo = optionPrices.find(t => t.value === id);
            const tempSelected = selectedOptionByGeo || optionPrices[0];
            setPrice(tempSelected);
    
            const tempArticlePrice = articlePrices.find(t => t.geoId === tempSelected.value);
            if (tempArticlePrice && onPriceSelect) {
                onPriceSelect(tempArticlePrice);
            }
        }
    }

    const updateSelectedAmount = (id: string, amount: number) => {
        if (allowToSelectPrice && optionPrices && optionPrices.length > 0) {
            setAmount(amount);
            if (onAmountSelect) {
                onAmountSelect(id, amount);
            }
        }
    }

    const handleSelect = () => {
        if (onDeselect && isSelected) {
            onDeselect(id);
        }
        if (onSelect && !isSelected) {
            onSelect(id);
        }
    }

    const onPriceChange = (option: SelectOption) => {
        updateSelectedPrice(option.value);
    }

    const onAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        updateSelectedAmount(id, +newValue);
    }

    const handleToggleExpand = () => {
        if (linesToShow === 3) {
            setlinesToShow(textLines.length);
        } else {
            setlinesToShow(3);
        }
    }

    const selectableButton = isSelectable ?
    <IconButton aria-label="settings" onClick={handleSelect}>
        <Icon />
    </IconButton> :
    <></>;

    return (
        <Box className={classes.card_container}>
            <div className={classes.image_container}>
                <ShowImage imageName={imageName} width={imageWidth} height={imageHeight}/>
            </div>
            
            <Box>
                <CardContent className={classes.box_container}>
                    <Typography variant="subtitle1" className={classes.title} component="div">
                        {title}
                    </Typography>
                    {!allowToSelectPrice && <div className={classes.prices_container}>
                        {articlePrices.map((articlePrice, i) => (
                            <span>
                                {articlePrice.geo?.name} ${articlePrice.price}{i < articlePrices.length - 1 && <span>,</span>}
                            </span>
                        ))}
                    </div>}
                    {allowToSelectPrice && <div className={classes.prices_select_container}>
                        <Select onChange={onPriceChange}
                            selected={price}
                            elements={optionPrices}
                            label={"Seleccione el precio"}/>
                        <div className={classes.amount_input_contaier}>
                            <TextInput
                                type={TextInputType.NUMBER}
                                value={amount}
                                onChange={onAmountChange}
                                label={'Cantidad'}
                                className={classes.amount_input}
                                fullWidth
                            />
                        </div>
                    </div>}
                    {textLines.slice(0, linesToShow).map(t => <div>{t}</div>)}
                    {textLines.length > 3 && linesToShow !== textLines.length && <div><Link text={i18n.translate("SEE_MORE")} onClick={handleToggleExpand}></Link></div>}
                    {textLines.length > 3 && linesToShow === textLines.length && <div><Link text={i18n.translate("SEE_LESS")} onClick={handleToggleExpand}></Link></div>}
                </CardContent>
            </Box>
            <div className={classes.filler}></div>
            <Box className={classes.checkbox_container}>
                {selectableButton}
            </Box>
        </Box>
    )
}

export default ShowArticleDeliveryItem;