import WeightPriceCalculatorProperties from "./WeightPriceCalculatorProperties";
import React from "react";
import {useStyles} from "./WeightPriceCalculator.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import {Observer} from "mobx-react-lite";
import WeightInput from "../../Inputs/WeightInput/WeightInput";
import ShowText from "../../Info/ShowText/ShowText";
import clsx from "clsx";
import PriceInputShort from "../../Inputs/PriceInputShort/PricetInputShort";
import { CurrencyModule } from "../../../util/Currency";

const WeightPriceCalculator: React.FC<WeightPriceCalculatorProperties> =({
                                                                            weightInfo,
                                                                            onWeightChange,
                                                                            onFinalWeightPriceChange,
                                                                            fullWidth=false,
                                                                            className='',
                                                                        })=>{

    const classes = useStyles();
    const i18n = useI18nContext();

    const setWeight = (newValue:number) =>{
        onWeightChange(newValue);
    }

    const onWeighPriceChange = (price:number)=>{
        onFinalWeightPriceChange(price);
    }

    return (
        <Observer>
            {() => (
                <div className={clsx(classes.weight_section_container)}>
                    <WeightInput onChange={setWeight} value={weightInfo?.weight}/>
                    <ShowText title={i18n.translate("WEIGHT_RANGE")} text={CurrencyModule.format(weightInfo?.range?.price||0)+` ${weightInfo?.range?.from}-${weightInfo?.range?.to} (lb)`}/>
                    <ShowText title={"Sugerencia"} text={CurrencyModule.format(weightInfo?.subWeightPrice|| 0)}/>
                    <PriceInputShort label={"Precio por pesaje"} value={weightInfo?.finalWeightPrice} onChange={onWeighPriceChange}/>
                </div>
            )}
        </Observer>
    )

}
export default WeightPriceCalculator