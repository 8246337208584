import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import ValidateTopupResponse from "../../model/topup/ValidateTopupResponse";
import ValidateTopupRequest from "../../model/topup/ValidateTopupRequest";
import TopupOrderRequest from "../../model/topup/DoTopupRequest";
import TopupToDo from "../../model/topup/TopupToDo";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import TopupOrder from "../../entities/productOrder/topup/TopupOrder";
import AddPaymentRequest from "../../model/payment/AddPaymentRequest";
import TopupOrderResponse from "../../model/topup/TopupOrderResponse";
import ConfirmTopupResponse from "../../model/topup/ConfirmTopupResponse";

export module TopupService {
    export async function get(
        page: number = 1,
        pageSize: number = 15,
        clientId?: string,
        contactId?: string,
        agencyId?: string,
        filter?: string,
        from?: number | undefined | null,
        to?: number | undefined | null,
        geoId?: string
    ): Promise<Response<PagedResponse<TopupOrderResponse>>> {
        const params = {page, pageSize, clientId, contactId, from, to, geoId, agencyId, filter, status: null};
        const url = Config.get("GET_TOPUPS_URL", params);
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const topupsData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (topupsData && paginationData) {
                const data = topupsData.map((t: any) => new TopupOrderResponse(
                    t.id,
                    t.agencyId,
                    t.orderNumber,
                    t.agencyName,
                    t.clientId,
                    t.clientName,
                    t.contactId,
                    t.contactName,
                    t.userId,
                    t.userName,
                    t.productId,
                    t.productName,
                    t.serviceFee,
                    t.totalToPay,
                    t.serviceCost,
                    t.status,
                    t.createdAt,
                    t.geo,
                    t.confirmation,
                    t.error,
                    t.comment,
                    t.phone));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<TopupOrderResponse>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<TopupOrderResponse>>(false, undefined, response.error, response.status);
    }

    export async function validate(topup: ValidateTopupRequest): Promise<Response<ValidateTopupResponse>> {
        const url = Config.get("VALIDATE_TOPUP_URL");
        const request = new Request(url, topup);
        return await RestClient.post(request);
    }

    export async function doTopup(topup: TopupToDo, payments: AddPaymentRequest[]): Promise<Response<TopupOrder>> {
        const url = Config.get("DO_TOPUP_URL");
        const request = new Request(url, new TopupOrderRequest(topup.client.id, topup.contact.id, topup.product.id, payments, topup.agencyId, topup.userId, topup.comment, topup.anonymousNumber));
        const response = await RestClient.post(request);
        if (response.success) {
            return new Response<TopupOrder>(true, response.data, undefined, response.status);
        }
        return new Response<TopupOrder>(false, undefined, response.error, response.status);
    }

    export async function confirm(orderId: string): Promise<Response<ConfirmTopupResponse>> {
        const url = Config.get("CONFIRM_TOPUP_URL", {id: orderId});
        const request = new Request(url);
        return await RestClient.get(request);
    }
}