import React, { useEffect } from 'react';
import DeliveryTypeSelectProperties from "./DeliveryTypeSelectProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./DeliveryTypeSelect.style";
import PackageDeliveryTypeSelect from '../../../Selects/PackageDeliveryTypeSelect/PackageDeliveryTypeSelect';
import { PackageDeliveryType } from '../../../../model/packageDelivery/PackageDeliveryType';
import ShowIntervalWithTitle from '../../../Info/ShowIntervalWithTitle/ShowIntervalWithTitle';
import ShowText from '../../../Info/ShowText/ShowText';
import Currency from '../../../../entities/Currency';
import { CurrencyModule } from '../../../../util/Currency';
import { useI18nContext } from '../../../../contexts/I18nContext';
import MessageCard from '../../../../ui_components/MessageCard/MessageCard';

const DeliveryTypeSelect: React.FC<DeliveryTypeSelectProperties> = ({
                                                            store
                                                        }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    useEffect(() => { store.searchCustomCategories() });
    
    const onTypeChange = (newType: PackageDeliveryType) => {
        store.deliveryType = newType;
    }

    return <Observer>
        {() => (
            <div className={classes.container}>
                <div className={classes.delivery_type_select_container}>
                    <PackageDeliveryTypeSelect 
                        selected={store.deliveryType}
                        onChange={onTypeChange}
                        className={classes.input}
                        disabled={store.itemsAndCategories.length > 0}
                        fullWidth
                    />
                </div>
                <div className={classes.values_container}>
                    <div className={classes.range_info_container}>
                    {store.customCategories.map((customCategory, i) => {
                        const items = customCategory.weightRanges.filter(
                            x => x.deliveryType.toString() === PackageDeliveryType[store.deliveryType!!]
                        );

                        return (
                            <div key={i}>
                                <div className={classes.category_name}>{customCategory.name}</div>
                                <div className={classes.range_item_container}>
                                    {items.map((item, j) => (
                                        <div key={j} className={classes.range_item}>
                                            <ShowIntervalWithTitle 
                                                title={i18n.translate("WEIGHT_RANGES_RANGE")} 
                                                from={item.from} 
                                                to={item.to} 
                                            />
                                            <ShowText 
                                                text={CurrencyModule.format(item.price, Currency.DOLLAR)} 
                                                title={i18n.translate("WEIGHT_RANGES_PRICE")} 
                                            />
                                        </div>
                                    ))}
                                    {items.length === 0 && <div>
                                        <MessageCard className={classes.empty_delivery_type} title={'No hay precios para este tipo de envío!!!'}/>    
                                    </div>}
                                </div>
                            </div>
                        );
                    })}
                    </div>
                </div>
            </div>
        )}
    </Observer>
}

export default DeliveryTypeSelect;
