import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceInputProperties from "./PriceInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import TextInputType from '../../../ui_components/TextInput/TextInputType';

const PriceInput: React.FC<PriceInputProperties> = ({
                                                        value,
                                                        label,
                                                        placeholder,
                                                        onChange,
                                                        disabled,
                                                        fullWidth = false,
                                                        autoFocus = false,
                                                        className = '',
                                                        error,
                                                        icon = AttachMoneyIcon
                                                    }) => {

    const [amount, setAmount] = useState(value);
    const i18n = useI18nContext();

    useEffect(() => {
        setAmount(value);
    }, [value]);

    const onAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newAmount = +event.target.value;
        setAmount(newAmount);
        onChange(newAmount);
    }

    return (
        <TextInput
            type={TextInputType.NUMBER}
            error={error}
            label={label ? label : i18n.translate("PRICE")}
            value={amount}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onAmountChange}
            startIcon={icon}
            className={className}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
        />
    )
}


export default PriceInput;