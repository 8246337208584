import React from "react";
import {useI18nContext} from "../../../contexts/I18nContext";
import {CurrencyModule} from "../../../util/Currency";
import Table from "../../../ui_components/Table/Table";
import TableType from "../../../ui_components/Table/TableType";
import ShowArticleDeliveryDetailsProperties from "./ShowArticleDeliveryDetailsProperties";
import { useStyles } from "./ShowArticleDeliveryDetails.style";
import ArticleWithPriceResponse from "../../../model/articleDelivery/ArticleWithPriceResponse";

const ShowArticleDeliveryDetails: React.FC<ShowArticleDeliveryDetailsProperties> = ({
                                                            articles,
                                                            selectedArticlesPrices
                                                          }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const getArticleSelectedPrice = (article: ArticleWithPriceResponse) => {
        const tempSelected = selectedArticlesPrices.find(selectedPrice => selectedPrice.articleId === article.id);
        return tempSelected ? tempSelected.price : 0;
    }

    const tableHeader = [
        'Artículo',
        'Cantidad',
        'Precio seleccionado',
        'Total'
    ]

    const tableBody = articles.map(p => [
        p.name,
        p.amount.toString(),
        '$ ' + getArticleSelectedPrice(p).toString(),
        '$ ' + (p.amount * getArticleSelectedPrice(p)).toString()
    ]);

    const total =  selectedArticlesPrices.reduce((total, obj) =>
            total + (obj.price * (articles.find(article => article.id === obj.articleId)!!.amount)), 0);

    tableBody.push([
        i18n.translate("TOTAL"),
        articles.reduce((n, t) => n + t.amount, 0).toString(),
        '',
        CurrencyModule.format(total, 'USD')]);
    
    return (
        <div className={classes.container}>
            <div className={classes.info_container}>
                <Table title={'Artículos'} tableType={TableType.SECONDARY} body={tableBody}
                       header={tableHeader}/>
            </div>
        </div>
    );
};

export default ShowArticleDeliveryDetails;
