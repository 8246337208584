import React, {useEffect, useState} from 'react';
import ArticleDescriptionInputProperties from "./ArticleDescriptionInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import Textarea from '../../../ui_components/Textarea/Textarea';
import DescriptionIcon from '@mui/icons-material/Description';

const ArticleDescriptionInput: React.FC<ArticleDescriptionInputProperties> = ({
                                                                  value,
                                                                  placeholder,
                                                                  onChange,
                                                                  onBlur,
                                                                  onFocus,
                                                                  disabled,
                                                                  fullWidth = false,
                                                                  className = '',
                                                                  error,
                                                                  lines = 10
                                                              }) => {

    const [name, setName] = useState(value);
    const i18n = useI18nContext();

    useEffect(() => {
        setName(value);
    }, [value]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newName = event.target.value;
        setName(newName);
        onChange(newName);
    }

    const onBlurEvent = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onBlur && onBlur(e);
    }

    const onFocusEvent = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onFocus && onFocus(e);
    }

    return (
        <Textarea
            error={error}
            label={i18n.translate("DESCRIPTION")}
            value={name}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onNameChange}
            onBlur={onBlurEvent}
            onFocus={onFocusEvent}
            startIcon={DescriptionIcon}
            className={className}
            fullWidth={fullWidth}
            lines={lines}
        />
    )
}


export default ArticleDescriptionInput;