import UpdateDeliveryOrderStatusButtonProperties from "./UpdateDeliveryOrderStatusButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import UpdateDeliveryOrderStatusStore from "./UpdateDeliveryOrderStatusStore";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./UpdateDeliveryOrderStatusButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import CommentInput from '../../Inputs/CommentInput/CommentInput';
import ProductOrderStatusSelect from "../../Selects/ProductOrderStatusSelect/ProductOrderStatusSelect";
import {useUserContext} from "../../../contexts/UserContext";
import React from "react";
import Link from "../../../ui_components/Link/Link";
import Access from "../../Access/AllowAccess/Access";


const UpdateDeliveryOrderStatusButton: React.FC<UpdateDeliveryOrderStatusButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  deliveryOrderType,
                                                                  productOrderId
                                                              }) => {
    const classes = useStyles();

    const i18n = useI18nContext();
    const userStore = useUserContext();
    const commonContext = useCommonContext();
    const store = new UpdateDeliveryOrderStatusStore(commonContext, productOrderId, deliveryOrderType);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onCommentChange = (newComment: string) => (store.comment = newComment);
    const onStatusChange = (option: string) => {
       store.status = option;
    }

    const onOrderStatusSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    if (!userStore.isRootAgency() || !userStore.hasAccess(Access.REMOVE_MONEY_DELIVERY_ORDER.valueOf())) {
        return <></>;
    }
    
    const Btn = () => {
        return <Link text={'Editar'} onClick={handleOnclick}></Link>;
    }

    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={'Editar estado'} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <ProductOrderStatusSelect fullWidth selected={store.status} onChange={onStatusChange}/>
                        <CommentInput
                            fullWidth
                            lines={3}
                            onChange={onCommentChange}
                            value={store.comment}
                            className={classes.input}/>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onOrderStatusSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default UpdateDeliveryOrderStatusButton;
