enum Access {
    NONE = 0,
    LIST_USER = 1,
    CREATE_USER = 2,
    LIST_AGENCY = 4,
    CREATE_AGENCY = 8,
    SET_AGENCY_MONEY_DELIVERY_EXCHANGE_RATE = 17592186044416,
    LIST_DELIVERY_PERSON = 16,
    CREATE_DELIVERY_PERSON = 32,
    ADD_BALANCE_TO_DELIVERY_PERSON = 64,
    GET_BALANCE_OF_DELIVERY_PERSON = 128,
    GET_TRANSACTIONS = 256,
    LIST_ALL_INVOICES = 512,
    LIST_MONEY_DELIVERY_FEE = 1024,
    CREATE_MONEY_DELIVERY_FEE = 2048,
    LIST_MONEY_DELIVERY_EXCHANGE_RATE = 4096,
    CREATE_MONEY_DELIVERY_EXCHANGE_RATE = 8192,
    SET_MONEY_DELIVERY_DELIVERY_PERSON = 16384,
    REMOVE_MONEY_DELIVERY_ORDER = 32768,
    LIST_SALE_PRICE = 65536,
    CREATE_SALE_PRICE = 131072,
    ADD_DELIVERY_COST = 262144,
    GET_AGENCY_SALES = 524288,
    GET_GENERAL_SALES = 8796093022208,
    UPDATE_MONEY_DELIVERY_CONTACT = 4194304,
    SEND_ORDERS_TO_AGENCIES = 8388608,
    TRANSFER_BALANCE_BETWEEN_DELIVERY_PERSON = 2097152,
    VIEW_TOTAL_DELIVERY_PERSON_BALANCES = 16777216,
    LIST_TOPUP_PRICES=33554432,
    CREATE_TOPUP_PRICE=67108864,
    MANAGE_ACCESS=134217728,
    UPDATE_USER_PASSWORD=9007199254740992,
    SHOW_USER_IN_STATUS_HISTORY=288230376151711744,
    SET_AGENCY_MAIN_USER_PASSWORD=4503599627370496,
    UPDATE_DELIVERY_PERSON_PASSWORD=18014398509481984,
    DISPLAY_DELIVERY_PERSON_IN_ORDERS=4611686018427387904,

    //CUSTOM CATEGORY
    LIST_CUSTOM_CATEGORY= 268435456,
    CREATE_EDIT_CUSTOM_CATEGORY = 536870912,
    REMOVE_CUSTOM_CATEGORY = 1073741824,

    //WEIGHT RANGES
    LIST_WEIGHT_RANGE = 2147483648,
    CREATE_EDIT_WEIGHT_RANGE = 4294967296,
    REMOVE_WEIGHT_RANGE = 8589934592,

    //INVENTORY ITEM
    LIST_INVENTORY_ITEM = 17179869184,
    CREATE_EDIT_INVENTORY_ITEM = 34359738368,
    REMOVE_INVENTORY_ITEM = 68719476736,

    //REFERENCE ITEM
    LIST_REFERENCE_ITEM_PRICE = 17592186044416,
    CREATE_EDIT_REFERENCE_ITEM_PRICE = 8796093022208,
    REMOVE_REFERENCE_ITEM_PRICE = 35184372088832,   

    //PACKAGE DELIVERY
    LIST_PACKAGE_DELIVERY_ORDER = 137438953472,
    CREATE_EDIT_PACKAGE_DELIVERY_ORDER = 274877906944,
    REMOVE_PACKAGE_DELIVERY_ORDER = 549755813888,
    SET_UNSET_PACKAGE_DELIVERY_ORDER_DELIVERY_PERSON = 1099511627776,
    UPDATE_PACKAGE_DELIVERY_ORDER_CONTACT = 2199023255552,
    SET_STATUS_PACKAGE_DELIVERY_ORDER = 4398046511104,

    UPDATE_PAYMENT_FEES = 35184372088832,

    // PROMOTIONS
    CREATE_PROMOTION = 1125899906842624,
    LIST_PROMOTION = 2251799813685248,

    // ARTICLE STOCK
    GET_ARTICLE_STOCK_OF_DELIVERY_PERSON = 36028797018963968,
    ADD_ARTICLE_STOCK_TO_DELIVERY_PERSON = 72057594037927936,
    TRANSFER_ARTICLES_BETWEEN_DELIVERY_PERSON = 144115188075855872,

    // SHOP PRODUCT
    CREATE_SHOP_PRODUCT = 576460752303423488,
    REMOVE_SHOP_PRODUCT = 1152921504606846976,
    LIST_SHOP_PRODUCT_STOCK_TRANSACTIONS = 2305843009213693952,
}

export default Access;