import { useConfirm } from "material-ui-confirm";
import { useI18nContext } from "../contexts/I18nContext";
import { useCommonContext } from "../contexts/CommonContext";

export default function useAlert(): (title: string, description: string) => Promise<void> {
    const confirm = useConfirm();
    const i18n = useI18nContext();
    const commonStore = useCommonContext();

    return async (title: string, description: string) => {
        try {
            await confirm({
                title,
                description,
                confirmationText: i18n.translate("OK"),
                cancellationText: "",
            });
        } finally {
            commonStore.emitEvent("dialog_closed");
        }
    };
}
